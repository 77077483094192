<template>
  <div id="app">
    <notifications group="noti2" :duration="5000" position="top right" />
    <notifications group="noti" position="bottom right" />
    <QuestionnairePage />
  </div>
</template>

<script>
import QuestionnairePage from './pages/QuestionnairePage.vue'

export default {
  name: 'App',
  components: {
    QuestionnairePage
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body {
  /* background: url(./assets/bk/1.jpg); */
  background: url(./assets/bk/2.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
}

.notification-title {
  font-size: 1.6em !important;
}
</style>
