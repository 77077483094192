<script>
//local registration
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import draggable from 'vuedraggable'
import questions from '../assets/questions.json'
import DownloadPdf from '@/components/DownloadPdfComponent.vue'
import FormPracticeComponentVue from '@/components/FormPracticeComponent.vue'
var saw = require('scorm-api-wrapper');
window.saw = saw

import questions_1 from '@/assets/exams/1.json';
import questions_2 from '@/assets/exams/2.json';
import questions_3 from '@/assets/exams/3.json';



//component code
export default {
    watch: {
    },
    components: {
        FormWizard,
        TabContent,
        draggable,
        DownloadPdf,
        FormPracticeComponentVue
    },
    data() {
        return {
            question_id: '',
            menu: false,
            test1: false,
            test2: false,
            end_score: false,
            examPractice: false,
            JsonQuestion: questions,
            scormInit: false,
            questions: 0,
            success: 0,
            score: 0,
            scoreAll: 0,
            scoreAllT: 0,
            pos_init: 0,
            valueScore: 0,
            modPos: 0,
            posMessage: 0,
            qualified: false,
            showInfoResponse: false,
            showTypeResponse: '',
            showMessageResponse: '',
            respons: [],
            local_periods: null,
            periods_scorm: null,
            periods: null,
            scores: [],
            showScore: false,
            positionComplete: [],
            init: false,
            endGameSuccess: [
                '¡Lo lograste! Nos vemos en el Módulo II.',
                '¡Bien hecho! Tú siguiente reto será el Módulo II.',
                '¡Buen trabajo! Continúa así en tu siguiente etapa.',
                '¡Lo lograste! Nos vemos en el Módulo II.',
                '¡Bien hecho! Tú siguiente reto será el Módulo II.'
            ],
            endGameErrror: [
                'Te invitamos a seguir estudiando y prepararte más, continuarás en el Módulo I.',
                'Puedes hacerlo, la próxima vez esfuérzate un poco más, reforzarás tu aprendizaje en tus cursos online y en el Módulo I.',
                'Sigue preparándote, la próxima vez lo lograrás con ayuda del Módulo I y tus cursos online.',
                'Te invitamos a seguir estudiando y prepararte más, continuarás en el Módulo I.',
                'oPuedes hacerlo, la próxima vez esfuérzate un poco más, reforzarás tu aprendizaje en tus cursos online y en el Módulo I.'
            ],
        }
    },
    mounted() {
        let n_random = Math.round(Math.random() * (3 - 1) + 1);
        this.question_id = n_random;
        if (n_random == 1) {
            this.JsonQuestion = questions_1;
        }
        if (n_random == 2) {
            this.JsonQuestion = questions_2;
        }
        if (n_random == 3) {
            this.JsonQuestion = questions_3;
        }

        this.parseQuestions();

        try {
            saw.initialize();
            this.loadScorm();
        } catch (error) {
            console.log(error)
        }

        this.showMenu();

    },
    methods: {
        parseQuestions() {
            let quetions = this.JsonQuestion;
            let q = [];
            let count = 0;
            let score = 0;
            quetions.forEach(function (items, index_question) {
                if (items.type == "ralation") {
                    if (items.calc == 'simple') {
                        score += parseFloat(items.value);
                    }
                    q[index_question] = new Array();
                    items.responses.forEach(function (item, indexs) {
                        if (items.calc == 'all') {
                            score += parseFloat(items.value);
                        }
                        q[index_question][indexs] = '';
                        count++;
                    });
                }
                if (items.type == "complete") {
                    score += parseFloat(items.value);
                    q[index_question] = new Array();
                    for (let index = 0; index < items.n_responses; index++) {
                        q[index_question][index] = '';
                        count++;
                    }
                }
                if (items.type == "option" || items.type == 'optionTF') {
                    score += parseFloat(items.value);
                    q[index_question] = new Array();
                    q[index_question][0] = '';
                    count++;
                }
            });
            this.respons = q;
            this.questions = count;
            console.log(parseFloat(score).toFixed(2));

        },
        downloadPDF() {
            Event.$emit('event-download-pdf');
        },
        showtest(position) {
            this.menu = false;
            if (position == 1) {
                this.test1 = true;
                this.test2 = false;
            }
            if (position == 2) {
                this.test1 = false;
                this.test2 = true;
            }
        },
        loadScorm() {
            this.getData()
        },
        qualifyGroup(group) {
            let sum = 0;
            let total = 0;

            let quetions = this.JsonQuestion;
            let _responses = this.respons;

            quetions.forEach(function (question, index) {
                if (question.group == group) {
                    let _response = _responses[index];
                    if (_response) {
                        if (question.type == "ralation") {
                            let position = 0;
                            let responsR = question.questions.length;
                            let countRlt = 0;
                            if (question.calc == 'all') {
                                _response.forEach(function (item) {
                                    total += parseFloat(question.value);
                                    if (item == position) {
                                        countRlt++;
                                        sum += parseFloat(question.value);
                                    }
                                    position++;
                                });
                            } else {
                                total += parseFloat(question.value);
                                _response.forEach(function (item) {
                                    if (item == position) {
                                        countRlt++;
                                    }
                                    position++;
                                });
                                if (responsR == countRlt) {
                                    sum += parseFloat(question.value);
                                }
                            }
                        }

                        if (question.type == "option" || question.type == 'optionTF') {
                            total += parseFloat(question.value);
                            if (question.responses[_response[0]]) {
                                if (question.responses[_response[0]].correct == true) {
                                    sum += parseFloat(question.value);
                                }
                            }
                        }

                        if (question.type == "complete") {
                            total += parseFloat(question.value);
                            let position = 0;
                            let responsC = question.n_responses;
                            let countCmp = 0;
                            _response.forEach(function (item) {
                                if (item == position) {
                                    countCmp++;
                                }
                                position++;
                            });
                            if (responsC == countCmp) {
                                sum += parseFloat(question.value);
                            }
                        }
                    }
                }
            });

            let message = '';
            let num = this.posMessage;
            let messageOK = ['¡Excelente!', '¡Lo hiciste muy bien!', '¡Buen trabajo!', '¡Excelente!', '¡Lo hiciste muy bien!'];
            let messageError = ['¡Sigue preparándote con el refuerzo de esta sección online!', '¡Continúa estudiando más en tu curso online!', 'Esfuérzate más y lo lograrás, tú siguiente reto será esta sección online.', '¡Sigue preparándote con el refuerzo de esta sección online!', '¡Continúa estudiando más en tu curso online!'];

            let score = parseFloat(sum).toFixed(2) / parseFloat(total).toFixed(2) * 100;
            if (score > 80) {
                message += `<h2 style='font-size: 45px;'> ${messageOK[num]} </h2>`;
            } else {
                message += `<h2 style='font-size: 45px;'> ${messageError[num]} </h2>`;
            }

            message += `<br><br<h3 style='font-size: 50px; font-weight: 900;'> ${parseFloat(score).toFixed(0)} / 100 </h3>`;
            // message += `<br><h4> ${parseFloat(sum).toFixed(2)} / ${parseFloat(total).toFixed(2)} </h4>`;

            let vm = this;
            if (group) {
                let JGroup = this.JsonQuestion;
                JGroup.forEach(function (valor) {
                    if (valor.type == "group") {
                        if (group == valor.id) {
                            let _name = valor.text;
                            _name = _name.replace("<br>", "");
                            let _group = { name: _name, score: parseFloat(score).toFixed(0), message: message, sum: sum };
                            vm.scores[group] = _group;
                        }
                    }
                });

            }
            return message;
        },
        getScoreEnd() {
            let scores = this.scores;
            let total = 0;
            scores.forEach(function (valor) {
                console.log(valor.sum);
                total += valor.sum;
            });
            this.scoreAll = total;
            return parseFloat(total).toFixed(2);
        },
        dragStart(item) {
            let posiontion = this.positionComplete;
            this.respons[posiontion[0]][posiontion[1]] = item.question;
            this.$forceUpdate();
        },
        showOption(__respons, index_question) {
            let respons = this.respons[index_question];
            if (respons.includes(__respons.question)) {
                return false;
            }
            return true;
        },
        completeText(question, index) {
            let text = question.questions;
            let respons = this.respons[index];
            question.responses.forEach(function (item, index_resp) {
                let __respons = "arrastra una opción";
                let __value = respons[index_resp];
                question.responses.forEach(function (item2) {
                    if (item2.question === __value) {
                        __respons = `<label class="rounded p-a4 rounded-pill  bg-white text-dark"> ${item2.text} <button class="btn btn-sm btn-danger float-right m-b" onclick="removeOption(${index},${index_resp})"><i class="fa-solid fa-trash "></i></button></label>`;
                    }
                });
                let input = `<div ondrop="getOption(event,${index},${index_resp})" ondragover="allowDrop(event)" class="div_inpunt d-inline form-control-sm w-auto" id="option_${index}_${index_resp}"> ${__respons}</div>`;
                text = text.replace(`$-${index_resp}`, input);
            });
            return text;
        },
        async validateAsync(index, question) {
            try {

                if (question.type == 'group') {
                    this.modPos = question.id;
                }

                let flag = this.validationResponses(index, question);

                if (flag) {
                    this.showMessageQuestion(index, question)
                }


                await new Promise((resolve, reject) => {
                    console.log(reject);
                    setTimeout(function () {
                        resolve("done");
                    }, 1000);
                }).then();
                return flag;
            } catch (error) {
                console.log(error);
            }
        },
        showMenu() {
            this.menu = true;
        },
        async onComplete() {


            try {
                let questions = this.JsonQuestion;
                let index = questions.length;
                index--;
                let question = questions[index];
                let flag = true;
                let validation = this.validationResponses(index, question);
                if (validation == true) {
                    this.getScore();
                    let response = this.respons;
                    Event.$emit('event-load-responses_1', response, this.question_id );
                }
                await new Promise((resolve, reject) => {
                    console.log(reject);
                    setTimeout(function () {
                        resolve("done");
                    }, 1000);
                }).then();
                return flag;
            } catch (error) {
                console.log(error);
            }
        },
        async onCompletePractice() {
            Event.$emit("event-load-responses_2", this.localPeriods);
        },
        getScore() {
            let quetions = this.JsonQuestion;
            let _responses = this.respons;
            let success = 0;

            quetions.forEach(function (question, index) {
                let _response = _responses[index];
                if (question.type == "ralation") {
                    let position = 0;
                    _response.forEach(function (item) {
                        if (item == position) {
                            success++;
                        }
                        position++;
                    });
                }

                if (question.type == "option" || question.type == 'optionTF') {
                    if (question.responses[_response[0]].correct == true) {
                        success++;
                    }
                }

                if (question.type == "complete") {
                    let position = 0;
                    _response.forEach(function (item) {
                        if (item == position) {
                            success++;
                        }
                        position++;
                    });
                }
            });

            this.qualified = true;
            this.score = (success / this.questions) * 100;

        },
        showScoreF() {
            this.$modal.hide('my-init');
            this.qualified = true;
        },
        validationResponses(index, question) {
            let flag = true;

            if (question.type == "ralation") {
                let res = question.responses.map(x => x['question']);
                this.respons[index] = res;
            }

            if (question.type == "option" || question.type == 'optionTF') {
                let _respons = this.respons[index];
                if (_respons[0] === '') {
                    flag = false;
                    this.$notify({
                        group: 'noti',
                        type: 'error',
                        title: 'Es necesario una respuesta',
                        text: 'Es necesario selecciona una respuesta'
                    });
                }
            }

            if (question.type == "complete") {
                let _respons = this.respons[index];
                _respons.forEach(function (item) {
                    if (item === "") {
                        flag = false;
                    }
                });

                if (flag == false) {
                    this.$notify({
                        group: 'noti',
                        type: 'error',
                        title: 'Es necesario completar la respuesta',
                        text: 'Arrastra la respuesta para completar la respuesta'
                    });
                }
            }
            // this.saveSuspendData();
            return flag;
        },
        sendNotificationQuestion(type) {
            let num = this.posMessage;
            let messageOK = ['¡Muy bien!', '¡Lo estás logrando!', '¡Excelente!', '¡Así se hace!', '¡Continúa así!'];
            let messageError = ['¡No te detengas!', '¡Confía en ti!', '¡Mantén la calma!', 'Vamos, ¡tú puedes!', '¡Lo lograrás!'];
            if (type == 'info') {
                this.showTypeResponse = 'bg-info';
                this.showMessageResponse = messageError[num];
            } else {
                this.showTypeResponse = 'bg-success';
                this.showMessageResponse = messageOK[num];
            }

            this.posMessage++;

            if (this.posMessage >= 4) {
                this.posMessage = 0;
            }

            let vm = this;
            this.showInfoResponse = true;
            setTimeout(function () {
                vm.showInfoResponse = false;
            }, 2000);

        },
        showMessageQuestion(index, question) {
            let _response = this.respons[index];
            if (question.type == "option" || question.type == 'optionTF') {
                if (question.responses[_response[0]].correct == true) {
                    this.sendNotificationQuestion('success');
                } else {
                    this.sendNotificationQuestion('info');

                }
            }

            if (question.type == "complete") {
                let position = 0;
                let Rsucces = 0;
                _response.forEach(function (item) {
                    if (item == position) {
                        Rsucces++;
                    }
                    position++;
                });

                if (Rsucces == _response.length) {
                    this.sendNotificationQuestion('success');
                } else {
                    this.sendNotificationQuestion('info');

                }
            }

            if (question.type == "ralation") {
                let position = 0;
                let Rsucces = 0;
                _response.forEach(function (item) {
                    if (item == position) {
                        Rsucces++;
                    }
                    position++;
                });

                if (Rsucces == _response.length) {
                    this.sendNotificationQuestion('success');
                } else {
                    this.sendNotificationQuestion('info');

                }
            }


        },
        getData() {
            // logica
            let vars = ['cmi.core.lesson_location', 'cmi.suspend_data']

            vars.forEach(element => {
                try {
                    let value = saw.getScormValue(element);
                    if (element == 'cmi.suspend_data') {
                        let items = value.split('%saa%');
                        items.forEach(item => {
                            console.log(item);
                            if (item) {
                                if (item.includes('score%')) {
                                    value = item.replace('score%', '')
                                    this.score = value;
                                }
                                if (item.includes('quiz%')) {
                                    let dataquiz = item.replace('quiz%', '')
                                    this.respons = JSON.parse(dataquiz);
                                }
                                if (item.includes('quizshow%')) {
                                    let dataquiz = item.replace('quizshow%', '')
                                    this.qualified = Number(dataquiz) == 1;
                                }
                                if (item.includes('practice%')) {
                                    let dataquiz = item.replace('practice%', '')
                                    this.examPractice = Number(dataquiz) == 1;
                                }
                                if (item.includes('quizpractice%')) {
                                    let dataquiz = item.replace('quizpractice%', '')
                                    this.periods = JSON.parse(dataquiz);
                                }
                                if (item.includes('question_id%')) {
                                    let question_id = item.replace('question_id%', '')
                                    if (question_id != this.question_id) {
                                        this.question_id = question_id;
                                        this.parseQuestions();
                                    }
                                }
                                if (item.includes('scoreshow%')) {
                                    let dataquiz = item.replace('scoreshow%', '')
                                    this.showScore = Number(dataquiz) == 1;
                                }
                            }
                        })

                    }

                } catch (error) {
                    console.log(error)
                }
            })

            let quetions = this.JsonQuestion;
            let respons = this.respons;
            let group = 0;
            let groupN = 0;
            let vm = this;
            let i = 0;
            let vNull = 0;
            quetions.forEach(function (items, index) {
                if (Array.isArray(respons[index])) {
                    if (items.group != groupN) {
                        groupN = items.group;
                    }
                    if (respons[index][0] !== "") {
                        if (items.group != group) {
                            vm.modPos = items.group;
                            group = items.group;
                            vm.qualifyGroup(items.group);
                        }
                        i++;
                    }
                } else {
                    vNull++;
                    i++;
                }

            });
            if (i > vNull) {
                let p = i - vNull;
                let _index = vNull - (((groupN - group) * 2) + 1);
                this.pos_init = p + _index + 1;
                // this.getScoreEnd();
                // this.getScore();
            }
        },
        saveSuspendData() {
            let scorm = this.score;
            let quizData = JSON.stringify(this.respons);
            let showQuiz = this.qualified ? 1 : 0;

            let examPractice = this.examPractice ? 1 : 0;
            let showScore = this.showScore ? 1 : 0;

            let periodData = this.periods_scorm;
            let question_id = this.question_id;

            // let newSuspendData = `%saa%score%${scorm}%saa%quiz%${quizData}%saa%quizshow%${showQuiz}%saa%practice%${examPractice}%saa%scoreshow%${showScore}`;
            let newSuspendData = `%saa%score%${scorm}%saa%quiz%${quizData}%saa%quizshow%${showQuiz}%saa%practice%${examPractice}%saa%quizpractice%${periodData}%saa%scoreshow%${showScore}%saa%question_id%${question_id}`;
            saw.setScormValue('cmi.suspend_data', newSuspendData);
            saw.commit();
        },
        decode(str) {
            let items = str.split('%')
            let data = {}
            items.forEach(item => {
                let key_value = item.split('-')
                data[key_value[0]] = key_value[1]
            })

            return data
        },
        modulPosition(id) {
            if (this.modPos == id) {
                return 'fa-beat-fade text-info';
            } else {
                if (this.modPos > id) {
                    return 'text-success';
                } else {
                    return 'text-secondary';
                }
            }
        },
        showPractice() {
            this.modPos++;
            this.examPractice = true;
            Event.$emit('event-asign-periods', this.periods);
            this.$modal.hide('my-first-modal');
            this.$modal.hide('my-init');
        },
        calAllScore(result) {
            let all_score = this.scoreAll;
            this.scoreAllT = result;
            if (result) {
                all_score + result;
            }
            this.examPractice = false;
            this.showScore = true;
            this.end_score = true;
            this.test1 = false;
            this.test2 = false;
            console.log(this.scoreAll);
            // try {
            //     saw.setScormValue('cmi.core.score.raw', all_score);
            //     saw.commit();
            //     if (Number(all_score) >= 80) {
            //         console.log('COMPLETED')
            //         saw.setScormValue('cmi.core.lesson_status', 'completed');
            //         saw.commit();
            //     } else {
            //         saw.setScormValue('cmi.core.lesson_status', 'incomplete');
            //         saw.commit();
            //     }
            //     this.saveSuspendData()


            // } catch (error) {
            //     console.log('ERROR')
            //     console.log(error)
            // }


        }
    },
    created() {
        Event.$on("event-result-practice", result => {
            this.calAllScore(result);
        });
        Event.$on("event-get-drop", data => {
            this.positionComplete = data;
        });
        Event.$on("event-remove-option", posiontion => {
            this.respons[posiontion[0]][posiontion[1]] = '';
            this.$forceUpdate();
        });
        Event.$on("event-complete-practice", periods => {
            this.localPeriods = periods;
            this.onCompletePractice();
        })
        Event.$on("event-save-scorm", periods => {
            this.periods_scorm = JSON.stringify(periods);
            // this.saveSuspendData();
        })
    },
}
</script>
<template>
    <div class="row vh-100">

        <!-- Menu -->
        <div class="col-12 contNew" style="height: 420px; margin-top: 15%; margin-left: 5%; margin-right: 5%;width: 90%;"
            v-if="menu">
            <div class="row">
                <div class="col-12 mb-2 mt-2">
                    <img src="@/assets/BBVA_Logo-01.png" class="mb-4" style="max-width: 200px; width: 100%;" alt="">
                </div>
                <div class="col-6">
                    <button @click="showtest(1)" :disabled="test1 == true"
                        class="btn btn-lg btn-bbva btn-block w-100 text-white text-center pt-5 pb-5" style="height: auto;">
                        <i class="fa-solid fa-list-check fa-4x"></i><br>
                        <!-- <h3 class="pt-3">Iniciar examen teórico</h3> -->
                        <h3 class="pt-3">Iniciar examen teórico</h3>
                    </button>
                </div>
                <div class="col-6">
                    <button @click="showtest(2)" :disabled="test1 === false && test2 === false"
                        class="btn btn-lg btn-bbva btn-block w-100 text-white text-center pt-5 pb-5" style="height: auto;">
                        <i class="fa-solid fa-table-list fa-4x"></i><br>
                        <h3 class="pt-3">Iniciar examen práctico</h3>
                    </button>
                </div>
            </div>
        </div>
        <!-- End Menu -->

        <!-- Examen 1 -->
        <div class="col-12 col-md-10 offset-md-2" v-if="test1 == true && menu == false">
            <!-- Inice -->
            <div class="d_index d-none d-sm-none d-md-block">
                <img src="@/assets/BBVA_Logo-01.png" class="mb-4" style="max-width: 200px; width: 100%;" alt="">
                <div class="" :key="index_question" v-for="(question, index_question) in JsonQuestion">
                    <p class="mt-4" v-if="question.type == 'group'">
                        <i class="fa-solid fa-circle fa-lg float-right mt-4" :class="modulPosition(question.id)"></i> <span
                            class="ml-5" v-html="question.text"></span>
                    </p>
                </div>
            </div>
            <div class="m-5" v-if="showScore">
                <img :src="scoreAll > 80 ? require(`../assets/bk/Imagen2.png`) : require(`../assets/bk/Imagen3.png`)"
                    class="rounded h-img img-fluid">
                <button @click="downloadPDF()()" class="btn btn-lg btn-block btn-bbva text-white"
                    style=" margin-left: -19%; margin-top: -15%;">
                    Descargar PDF </button>
            </div>
            <div v-if="examPractice == false && showScore == false" class="card b-shadow mt-n5"
                style="background: transparent">
                <div class="card-body mt-n5">
                    <img class="d-none d-sm-block d-md-none" src="@/assets/BBVA_Logo-01.png"
                        style="width: 200px; padding-left: 40px; padding-top: 15px;" alt="">
                    <br>
                    <!-- Score final -->
                    <div class="col-12  text-center p-5" v-if="qualified">
                        <h1 class="mt-2 text-white" style="font-size: 55px;">
                            <strong>TU CALIFICACIÓN ES:</strong>
                        </h1>
                        <h2 class="text-light mt-4" style="font-size: 35px;">
                            <span v-if="score > 80">{{ endGameSuccess[posMessage] }}</span>
                            <span v-else>{{ endGameErrror[posMessage] }}</span>
                        </h2>
                        <h1 class="pt-5 pb-5 text-white" style='font-size: 60px; font-weight: 900;'> {{
                            parseFloat(score).toFixed(2) }} % </h1>

                        <div class="col-12 mb-5 box-blue">
                            <table class="table text-white">
                                <thead>
                                    <tr>
                                        <th>Sección</th>
                                        <th>Puntos</th>
                                        <th>Calificación</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(score, index_score) in scores" :key="index_score">
                                        <td class="text-left" v-if="score">
                                            {{ score.name }}
                                        </td>
                                        <td v-if="score" :class="score.score > 80 ? 'bg-success' : 'bg-primary'">
                                            {{ parseFloat(score.sum).toFixed(2) }}
                                        </td>
                                        <td v-if="score" :class="score.score > 80 ? 'bg-success' : 'bg-primary'">
                                            {{ score.score }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <h5 class="text-light" style="font-size: 40px;">
                            <span> Puntuación <br>{{ getScoreEnd() }}</span>
                        </h5>
                        <div class="row">
                            <div class="col-12 mt-4">
                                <button class="btn btn-lg btn-block btn-bbva text-white" @click="showMenu()"> Continuar
                                </button>
                            </div>
                        </div>
                    </div>
                    <form-wizard v-else color="#13923b" step-size="xs" title="" subtitle="" back-button-text="Atras"
                        next-button-text="Siguiente" finish-button-text="Finalizar" @on-complete="onComplete"
                        :start-index="pos_init">
                        <tab-content>
                            <div class="row justify-content-center mt-5">
                                <div class="col-12 contNew">
                                    <img src="@/assets/bk/Imagen1.png" class="img-fluid rounded" />
                                </div>
                            </div>
                        </tab-content>
                        <tab-content title="" v-for="(question, index_question) in JsonQuestion" :key="index_question"
                            :before-change="() => validateAsync(index_question, question)">
                            <!-- Intro text -->
                            <div class="row justify-content-center text-left text-white" v-if="question.type == 'text'">
                                <div class="col-12 contNew mt-5 mb-5">
                                    <div class=" text-white" v-html="question.text"> </div>
                                </div>
                            </div>
                            <!-- Intro Group -->
                            <div class="row mt-5 text-left text-white" v-if="question.type == 'group'">
                                <h1 class="mt-2 text-white text-mod" style="font-size: 55px;">
                                    <strong>SECCIÓN {{ question.id }}</strong>
                                </h1>
                                <h1 class="pt-5 pb-5 text-white" style="font-size: 50px;" v-html="question.text"></h1>
                            </div>
                            <!-- Qualify Group -->
                            <div class="row mt-5 text-left text-white" v-if="question.type == 'qualify'">
                                <h1 class="mt-2 text-white text-mod" style="font-size: 55px;">
                                    <strong>SECCIÓN {{ question.group }}</strong>
                                </h1>
                                <h1 class="pt-5 pb-5 text-white" style="font-size: 50px;" v-html="question.text"></h1>
                                <div class="p-2 text-white" v-html="qualifyGroup(question.group)"> </div>
                            </div>
                            <!-- Completa -->
                            <div class="row justify-content-center text-white" v-if="question.type == 'complete'">
                                <div class="col-12 contNew mt-5 mb-5">
                                    <span class="tit_help">Arrastra la o las palabras que complemente la oración de forma
                                        correcta en los espacios en blanco.</span>
                                    <h2 class="mt-4 t-q">{{ question.text }}</h2>
                                    <div class="mt-4" style="text-align: justify; font-size: 25px; line-height: 2.5;"
                                        v-html="completeText(question, index_question)"></div>
                                </div>
                                <div class="col-md-10 p-2 " v-for="(respons_json, index_response) in question.responses"
                                    :key="index_response">
                                    <p draggable="true" :id="respons_json.question"
                                        v-if="showOption(respons_json, index_question)" @dragend="dragStart(respons_json)"
                                        class="rounded text-left bg-white text-dark rounded-pill  p-2 w-100">
                                        <i class="fa-solid fa-hand-pointer float-left mr-2" style="color: #13923b;"></i>
                                        {{ respons_json.text }} - {{ respons_json.question }}
                                    </p>
                                </div>
                            </div>
                            <!-- Opciones -->
                            <div class="row justify-content-center text-white"
                                v-if="question.type == 'option' || question.type == 'optionTF'">
                                <div class="col-12 contNew mt-5 mb-5">
                                    <span class="tit_help" v-if="question.type == 'option'">Marca el inciso de la respuesta
                                        correcta</span>
                                    <span class="tit_help" v-else>Marca el inciso de la respuesta correcta si la oración es
                                        verdadera o falsa.</span>
                                    <h2 class="mt-4 t-q" v-html="question.text"> </h2>
                                </div>
                                <div class="col-md-10 p-2 " v-for="(respons_json, index_response) in question.responses"
                                    :key="index_response">
                                    <div class=" p-2 text-left rounded-pill bg-white text-dark w-100">
                                        <label class="content-input">
                                            <input type="radio" v-model="respons[index_question][0]"
                                                :name="`question_${index_question}`" :value="index_response">
                                            {{ respons_json.text }}
                                            <i class="fa fa-check" v-if="respons_json.correct === true"></i>
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <!-- Relacion de columnas -->
                            <div class="row text-white" v-if="question.type == 'ralation'">
                                <div class="col-12 contNew mt-5 mb-5">
                                    <span class="tit_help">Relaciona los términos, conceptos, definiciones o descripciones
                                        de la primera columna con las opciones o características de la segunda
                                        columna.</span>
                                    <h2 class="mt-4 t-q"> {{ question.text }} </h2>
                                </div>
                                <div class="col-md-6 text-center">
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <div class="p-3 mb-2 text-light"> Pregunta</div>
                                        </div>
                                        <div class="col-12 text-center"
                                            v-for="(question, index_response) in question.questions" :key="index_response">
                                            <div class="p-3 mb-3 bg-info shadow rounded text-white"> {{ question.text }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 text-center">
                                    <div class="row">
                                        <div class="col-12 text-center">
                                            <div class="p-3 mb-2  text-light"> Repuestas</div>
                                        </div>
                                        <draggable v-model="question.responses">
                                            <div class="p-3 mb-3 shadow bg-light rounded text-dark"
                                                v-for="response_rel in question.responses" :key="response_rel.question">
                                                <i class="fa-solid fa-hand-pointer float-left"></i>
                                                {{ response_rel.name }}
                                            </div>
                                        </draggable>
                                    </div>
                                </div>
                            </div>
                            <transition name="slide">
                                <div v-if="showInfoResponse" class="notificationResponse" :class="showTypeResponse">
                                    <i v-if="showTypeResponse == 'bg-success'" class="fa-solid fa-circle-check fa-1x"></i>
                                    <i v-if="showTypeResponse == 'bg-info'" class="fa-sharp fa-solid fa-circle-info"></i>
                                    <strong v-if="showTypeResponse == 'bg-info'"> {{ showMessageResponse }}</strong>
                                    <strong v-if="showTypeResponse == 'bg-success'"> {{ showMessageResponse }}</strong>
                                </div>
                            </transition>

                        </tab-content>

                    </form-wizard>
                </div>
            </div>
        </div>
        <!-- End Examen 1 -->

        <!-- Examne 2 -->
        <div class="m-5 p-5" v-if="test2 == true && menu == false">
            <FormPracticeComponentVue />
        </div>
        <!-- End Examen 2 -->

        <!-- PDF -->
        <DownloadPdf />
        <!-- End Others -->

        <!-- Modal -->
        <modal name="my-first-modal" :width="'70%'" :height="'80%'">
            <img :src="require(`../assets/bk/Imagen4.png`)" class="rounded h-img img-fluid">
            <div class="row">
                <div class="col-12 text-left">
                    <button @click="showPractice()" class="btn btn-lg btn-block btn-bbva text-white"
                        style=" margin-left: 15%; margin-top: -15%;">
                        Continuar </button>
                </div>
            </div>
        </modal>
        <!-- End Modal -->

        <!-- Score  -->
        <div class="col-12 contNew" v-if="end_score"
            style="height: 650px; margin-top: 5%; margin-left: 5%; margin-right: 5%;width: 90%;">
            <div class="row">
                <div class="col-12 mb-2 mt-2">
                    <img src="@/assets/BBVA_Logo-01.png" class="mb-4" style="max-width: 200px; width: 100%;" alt="">
                </div>

                <h3 class="text-white">Por tu calificación te encuentras en el grupo de:</h3>
                <div class="col-12 mt-4" v-if="(this.scoreAll + this.scoreAllT) <= 79">
                    <h2 class="text-white">Grupo Principiante</h2>
                    <h4 class="text-white mt-4 mb-4">Iniciarás en el Curso Básico Online</h4>
                </div>
                <div class="col-12" v-if="(this.scoreAll + this.scoreAllT) >= 80 && (this.scoreAll + this.scoreAllT) <= 94">
                    <h2 class="text-white">Grupo Estándar</h2>
                    <h4 class="text-white mt-4 mb-4">Iniciarás en el Módulo 1</h4>
                </div>
                <div class="col-12" v-if="(this.scoreAll + this.scoreAllT) >= 95">
                    <h2 class="text-white">Grupo Maduro</h2>
                    <h4 class="text-white mt-4 mb-4">Iniciarás en el Módulo 2</h4>

                </div>
                <div class="col-12 col-md-10 offset-md-1 mb-5 box-blue">
                    <table class="table text-white">
                        <thead>
                            <tr>
                                <th>Examen</th>
                                <th>Calificación</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Examen teórico</td>
                                <td>{{ parseFloat(this.scoreAll).toFixed(2) }}</td>
                            </tr>
                            <tr>
                                <td>Examen práctico</td>
                                <td>{{ parseFloat(this.scoreAllT).toFixed(2) }}</td>
                            </tr>
                            <tr>
                                <td>Total</td>
                                <td> {{ parseFloat((this.scoreAll + this.scoreAllT)).toFixed(2) }} </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-12 text-center">
                    <button @click="downloadPDF()()" class="btn btn-lg btn-block btn-bbva text-white">
                        Descargar PDF </button>
                </div>
            </div>
        </div>
        <!-- End Score -->
    </div>
</template>
<style>
.float-left {
    float: left;
}

.b-shadow {
    border: 0px;
}

.w-auto {
    /* width: 180px; */
    width: auto;
}

.wizard-progress-with-circle {
    display: none;
}

.wizard-nav.wizard-nav-pills {
    display: none;
}

.p-a4 {
    min-width: 100px;
    text-align: center;
    padding-left: 10px;
}

.div_inpunt {
    background: transparent;
    border: transparent;
    border-bottom: 1px solid #fff;
    border-radius: 0px;
    padding: 10px;
}

.float-right {
    float: right;
}

.text-left {
    text-align: left;
}

.m-b {
    margin: 5px;
    font-size: 12px;
}

.mr-2 {
    margin-left: 10px;
    margin-right: 10px;
}

.content-input {
    margin: 5px 30px 5px 20px;
    text-align: justify;
    display: block;
    position: relative;
    padding-left: 45px;
    margin-right: 45px;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 18px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.content-input input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 5%;
    left: 8px;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border: 1px solid #acacac;
    border-radius: 20px;
}

/* On mouse-over, add a grey background color */
.content-input:hover input~.checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.content-input input:checked~.checkmark {
    background-color: #13923b;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.content-input input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.content-input .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.tit_help {
    color: #a5a4a4;
    font-size: 20px;
}

.wizard-card-footer {
    margin-top: 50px;
}

.vue-form-wizard .wizard-header {
    padding: 0px;
}

.wizard-footer-left {
    display: none;
}

button.wizard-btn,
.btn-bbva {
    width: 200px;
    height: 60px;
    border-radius: 15px !important;
    font-size: 20px !important;
    border: 2px solid #fff !important;
    background: rgb(62, 165, 220);
    background: linear-gradient(180deg, rgba(62, 165, 220, 1) 0%, rgba(31, 165, 169, 1) 100%) !important;
}

.q {
    background: url('../assets/bk/q.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: top;
    padding: 100px 80px 0px 90px;
    height: 350px;
}

.bg-info {
    background: #24a5b4 !important;
}

.text-mod:after {
    content: '';
    height: 4px;
    background: white;
    display: table;
    width: 300px;
    margin-top: 8px;
}

.d_index {
    overflow: auto;
    padding: 30px 25px 30px 25px;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 23%;
    background: rgb(14 51 99 / 85%);
    border-radius: 0px 10px 10px 0px;
    color: #fff;
    height: 100%;
    font-size: 16px;
    text-align: initial;
}

.vue-form-wizard .wizard-tab-content {
    padding: 0px 0px 10px 40px !important;
}

.t-q {
    font-size: 25px;
}

.notificationResponse {
    width: 270px;
    width: auto;
    color: #fff;
    border: 2px solid;
    border-radius: 15px;
    padding: 15px;
    position: absolute;
    bottom: 40px;
    margin-left: 7%;
    font-size: 20px;
}

.bg-success {
    background-color: #01A5A6 !important;
}

.bg-primary {
    background-color: #0F71BC !important;
}

.bg-info {
    background-color: #014284 !important;
}

.contenIntro {
    background-color: #014284d6;
    font-size: 18px;
    border: 2px solid #fff;
    margin: 20px;
    border-radius: 20px;
}

.slide-enter-active {
    animation: scale-up-hor-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.slide-leave-active {
    animation: scale-down-ver-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-hor-center {
    0% {
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
    }

    100% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
}

@keyframes scale-up-hor-center {
    0% {
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
    }

    100% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
}


@-webkit-keyframes scale-down-ver-center {
    0% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
    }

    100% {
        -webkit-transform: scaleY(0.0);
        transform: scaleY(0.0);
    }
}

@keyframes scale-down-ver-center {
    0% {
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
    }

    100% {
        -webkit-transform: scaleY(0.0);
        transform: scaleY(0.0);
    }
}

.contNew {
    width: 90%;
    margin-left: 10%;
    margin-right: 10%;
    background: rgb(28, 95, 158);
    background: linear-gradient(180deg, rgba(28, 95, 158, 1) 0%, rgba(14, 51, 99, 1) 100%);
    padding: 30px;
    border: 4px solid #fff;
    border-radius: 20px;
    box-shadow: rgb(255, 255, 255) 0px -9px 27px -5px, rgb(255, 255, 255) 1px 15px 16px -8px;
}

.contNew:before {
    content: "";
    display: block;
    width: 60%;
    height: 13%;
    background: transparent;
    position: absolute;
    margin-top: -50px;
    margin-left: -50px;
    border-radius: 27px 0px 0px 0px;
    border-top: 6px solid #d5eeff;
    border-bottom: 6px solid transparent;
    border-left: 6px solid #d5eeff;
    box-shadow: rgb(197 197 197 / 28%) -8px -9px 7px 0px;
}

.box-blue {
    background: #113c69;
    border: 2px solid white;
    border-radius: 10px;
    padding: 10px;
}

.slide-fade-leave-active {
    position: absolute;
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.vm--modal {
    background-color: #0e2d54 !important;
    padding: 20px !important;
    border: 2px solid #fff !important;
    border-radius: 20px !important;
}

.h-img {
    height: 100%;
    max-height: 710px;
}

button:disabled {
    cursor: not-allowed;
    pointer-events: all !important;
}
</style>
