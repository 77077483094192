import Vue from 'vue'
import App from './App.vue';
import store from './store';

//global registration
import VueFormWizard from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
Vue.use(VueFormWizard)

Vue.config.productionTip = false
window.Event = new Vue();

import Notifications from 'vue-notification'
Vue.use(Notifications)

import vmodal from 'vue-js-modal'
Vue.use(vmodal)

new Vue({
  store,
  render: h => h(App),
  mounted: function () {
    window.drg = this;
  },
  methods: {
    transferData: function (index, index_respuesta) {
      Event.$emit('event-get-drop', [index, index_respuesta]);
    },
    removeOption: function (index, index_respuesta) {
      Event.$emit('event-remove-option', [index, index_respuesta]);
    }
  }
}).$mount('#app')
