import Vue from 'vue';
import Vuex from 'vuex'
import practices from './practices'

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        practices
    }
})