export default {
    basic: {
        nameCompany: 'CARMONA CONSTRUCCIONES'
    },
    results_status: {
        results_status: {
            title: true,
            label: 'RESULTADOS',
            bg: 'blue',
            color: 'white',
            colspan: '3'
        },
        national_net_sales: {
            title: false,
            label: 'VENTAS NETAS NACIONALES',
            type: 'number',
            required: false,
            disabled: true,
        },
        export_net_sales: {
            title: false,
            label: 'VENTAS NETAS EXPORTACIÓN',
            type: 'number',
            required: false,
            disabled: true,
        },
        sum_net_sales: {
            title: false,
            label: 'SUMA VENTAS NETAS',
            type: 'sum',
            negative: false,
            required: false,
            disabled: true,
            items: [
                'national_net_sales',
                'export_net_sales'
            ]
        },
        sales_cost: {
            title: false,
            label: 'COSTO DE VENTAS',
            type: 'number',
            required: false,
            disabled: true,
        },
        gross_profit: {
            title: false,
            label: 'UTLIZADA BRUTA',
            type: 'sum_sub',
            required: false,
            disabled: true,
            sum: ['sum_net_sales'],
            sub: ['sales_cost']
        },
        amortization: {
            title: false,
            label: 'AMORTIZACION',
            type: 'number',
            required: false,
            disabled: true,
        },
        depreciation: {
            title: false,
            label: 'DEPRECIACION',
            type: 'number',
            required: false,
            disabled: true,
        },
        admnistration_expenses: {
            title: false,
            label: 'GASTOS DE VENTA Y ADMINISTRACIÓN',
            type: 'number',
            required: false,
            disabled: true,
        },
        gross_profit_before: {
            title: false,
            label: 'UAFIR=UTILIDAD ANTES DE GTOS FINANC',
            type: 'sum_sub',
            required: false,
            disabled: true,
            sum: ['gross_profit'],
            sub: ['amortization','depreciation','admnistration_expenses']
        },
        ebitda: {
            title: false,
            label: 'EBITDA',
            type: 'sum_sub',
            required: false,
            disabled: true,
            sum: ['gross_profit'],
            sub: ['admnistration_expenses']
        },
        loss_utility_change: {
            title: false,
            label: 'PERDIDA.(UTILIDAD)CAMBIARIA',
            type: 'number',
            required: false,
            disabled: true,
        },
        loss_utility_posmon: {
            title: false,
            label: 'PERDIDA.(UTILIDAD) POS MON',
            type: 'number',
            required: false,
            disabled: true,
        },
        financial_product: {
            title: false,
            label: 'PRODUCTOS FINANCIEROS',
            type: 'number',
            required: false,
            disabled: true,
        },
        financial_expense: {
            title: false,
            label: 'GASTOS FINANCIEROS',
            type: 'number',
            required: false,
            disabled: true,
        },
        cost_financing: {
            title: false,
            label: 'COSTO INTEGRAL DE FINANCIAMIENTO',
            type: 'sum',
            negative: false,
            required: false,
            disabled: true,
            items: [
                'loss_utility_change',
                'loss_utility_posmon',
                'financial_product',
                'financial_expense',
            ]
        },
        other_expense: {
            title: false,
            label: 'OTROS GASTOS (ingresos)',
            type: 'number',
            required: false,
            disabled: true,
        },
        utility_before_isr_ptu: {
            title: false,
            label: 'UTILIDAD ANTES DE ISR Y PTU',
            type: 'sum_sub',
            required: false,
            disabled: true,
            sum: ['gross_profit_before'],
            sub: ['cost_financing', 'other_expense']
        },
        utility_before_taxes: {
            title: false,
            label: 'UTILIDAD ANTES DE IMPUESTOS A LA UTILIDAD',
            type: 'sum',
            negative: false,
            required: false,
            disabled: true,
            items: [
                'utility_before_isr_ptu'
            ]
        },
        taxes_ptu_deferred: {
            title: false,
            label: 'IMPUESTOS Y PTU DIFERIDO',
            type: 'number',
            required: false,
            disabled: true,
        },
        impac_causado: {
            title: false,
            label: 'IMPAC CAUSADO',
            type: 'number',
            required: false,
            disabled: true,
        },
        isr_causado: {
            title: false,
            label: 'ISR CAUSADO',
            type: 'number',
            required: false,
            disabled: true,
        },
        utility_before_partida: {
            title: false,
            label: 'UTILIDAD ANTES DE PARTIDA EXTRAORDINARIA',
            type: 'sum_sub',
            required: false,
            disabled: true,
            sum: ['utility_before_taxes'],
            sub: ['taxes_ptu_deferred', 'impac_causado', 'isr_causado']
        },
        loss_sub_afils: {
            title: false,
            label: 'PERDIDA SUB/AFILS',
            type: 'number',
            required: false,
            disabled: true,
        },
        utility_sub_afils: {
            title: false,
            label: 'UTILIDAD SUB/AFILS',
            type: 'number',
            required: false,
            disabled: true,
        },
        utility_loss_net: {
            title: false,
            label: 'UTILIDAD (PERDIDA)NETA',
            type: 'sum_sub',
            required: false,
            disabled: true,
            sum: ['utility_before_partida'],
            sub: ['loss_sub_afils', 'utility_sub_afils']
        },
    },
    step1: {
        basic: {
            title: true,
            label: 'BALANCE GENERAL',
            bg: 'bg-title',
            color: 'white',
            td: ['2021', '2022', 'Diferencias 2021 y 2022']
        },
        activos_circulantes: {
            activo_circulante: {
                title: true,
                label: 'ACTIVO CIRCULANTE',
                bg: 'bg-section',
                color: 'white',
                colspan: 4
            },
            caja_banco: {
                title: false,
                label: 'CAJA Y BANCOS',
                type: 'number',
                required: false,
                disabled: true,
            },
            inversiones_temporales: {
                title: false,
                label: 'INVERSIONES TEMPORALES',
                type: 'number',
                required: false,
                disabled: true,
            },
            clientes_docs_cobrar: {
                title: false,
                label: 'CLIENTES Y DOCTOS X COBRAR',
                type: 'number',
                required: false,
                disabled: true,
            },
            inventarios: {
                title: false,
                label: 'INVENTARIOS',
                type: 'number',
                required: false,
                disabled: true,
            },
            cuentas_cobrar_filiales: {
                title: false,
                label: 'CUENTAS POR COBRAR A FILIALES',
                type: 'number',
                required: false,
                disabled: true,
            },
            pagos_anticipados: {
                title: false,
                label: 'PAGOS ANTICIPADOS',
                type: 'number',
                required: false,
                disabled: true,
            },
            otros_act_circ_operativos: {
                title: false,
                label: 'OTROS ACT. CIRC. OPERATIVOS',
                type: 'number',
                required: false,
                disabled: true,
            },
            deudores_diversos: {
                title: false,
                label: 'DEUDORES DIVERSOS',
                type: 'number',
                required: false,
                disabled: true,
            },
            otros_act_circ_no_operativos: {
                title: false,
                label: 'OTROS ACT. CIRC.  NO OPERATIVOS',
                type: 'number',
                required: false,
                disabled: true,
            },
            suma_activo_circulante: {
                title: false,
                label: 'SUMA ACTIVO CIRCULANTE',
                type: 'sum',
                negative: false,
                required: false,
                disabled: true,
                items: [
                    'caja_banco',
                    'inversiones_temporales',
                    'clientes_docs_cobrar',
                    'cuentas_cobrar_filiales',
                    'inventarios',
                    'pagos_anticipados',
                    'otros_act_circ_operativos',
                    'deudores_diversos',
                    'otros_act_circ_no_operativos',
                ]
            }
        },
        activo_fijo: {
            activo_fijo: {
                title: true,
                label: 'ACTIVO FIJO',
                bg: 'bg-section',
                color: 'white',
                colspan: 4
            },
            prop_planta_equipo_net: {
                title: false,
                label: 'PROPIEDADES PLANTA Y EQUIPO NET',
                type: 'number',
                required: false,
                disabled: true,
            },
            inversion_subsidiarias: {
                title: false,
                label: 'INVERSIÓN EN SUBSIDIARIAS/AFILIAD',
                type: 'number',
                required: false,
                disabled: true,
            },
            cuentas_cobrar_filiales_fijo: {
                title: false,
                label: 'CTAS. POR COB A FILIALES',
                type: 'number',
                required: false,
                disabled: true,
            },
            otros_activos_fijos: {
                title: false,
                label: 'OTROS ACTIVOS FIJOS',
                type: 'number',
                required: false,
                disabled: true,
            },
            suma_activo_fijo: {
                title: false,
                label: 'SUMA ACTIVO FIJO',
                type: 'sum',
                negative: false,
                required: false,
                disabled: true,
                items: [
                    'prop_planta_equipo_net',
                    'inversion_subsidiarias',
                    'cuentas_cobrar_filiales_fijo',
                    'otros_activos_fijos',
                ]
            }
        },
        otros_activos: {
            otros_activos: {
                title: true,
                label: 'OTROS ACTIVOS',
                bg: 'bg-section',
                color: 'white',
                colspan: 4
            },
            activos_impuestos_diferidos: {
                title: false,
                label: 'ACTIVO POR IMPUESTOS DIFERIDOS',
                type: 'number',
                required: false,
                disabled: true,
            },
            patentes_licencias_marcas: {
                title: false,
                label: 'PATENTES LICENCIAS Y MARCAS',
                type: 'number',
                required: false,
                disabled: true,
            },
            credito_mercantil: {
                title: false,
                label: 'CRÉDITO MERCANTIL',
                type: 'number',
                required: false,
                disabled: true,
            },
            cargos_diferidos: {
                title: false,
                label: 'CARGOS DIFERIDOS',
                type: 'number',
                required: false,
                disabled: true,
            },
            suma_otros_activos: {
                title: false,
                label: 'SUMA OTROS ACTIVOS',
                type: 'sum',
                negative: false,
                required: false,
                disabled: true,
                items: [
                    'activos_impuestos_diferidos',
                    'patentes_licencias_marcas',
                    'credito_mercantil',
                    'cargos_diferidos',
                ]
            }
        },
        total_activos: {
            total_activos: {
                title: false,
                label: 'TOTAL ACTIVOS',
                bg: 'bg-title',
                type: 'sum',
                negative: false,
                required: false,
                disabled: true,
                items: [
                    'activos_circulantes',
                    'activo_fijo',
                    'otros_activos',
                ]
            }
        },
        
        pasivo_corto_plazo: {
            pasivo_corto_plazo: {
                title: true,
                label: 'PASIVO CORTO PLAZO',
                bg: 'bg-section',
                color: 'white',
                colspan: 4
            },
            bacomer_corto_plazo: {
                title: false,
                label: 'BANCOMER CORTO PLAZO',
                type: 'number',
                required: false,
                disabled: true,
            },
            otros_bancos_corto_plazo: {
                title: false,
                label: 'OTROS BANCOS CORTO PLAZO',
                type: 'number',
                required: false,
                disabled: true,
            },
            emesion_deuda_corto_plazo: {
                title: false,
                label: 'EMISIÓN DEUDA CORTO PLAZO',
                type: 'number',
                required: false,
                disabled: true,
            },
            porc_circ_largo_plazo_bancomer: {
                title: false,
                label: 'PORC. CIRC LARGO PLAZO BANCOMER',
                type: 'number',
                required: false,
                disabled: true,
            },
            porc_circ_largo_plazo_otros_banc: {
                title: false,
                label: 'PORC. CIRC LARGO PLAZO OTROS BANCOS',
                type: 'number',
                required: false,
                disabled: true,
            },
            proveedores: {
                title: false,
                label: 'PROVEEDORES',
                type: 'number',
                required: false,
                disabled: true,
            },
            impuestos_pagar: {
                title: false,
                label: 'IMPUESTOS POR PAGAR',
                type: 'number',
                required: false,
                disabled: true,
            },
            cuentas_pagar_filiales: {
                title: false,
                label: 'CUENTAS POR PAGAR FILIALES',
                type: 'number',
                required: false,
                disabled: true,
            },
            gastos_acumulados: {
                title: false,
                label: 'GASTOS ACUMULADOS',
                type: 'number',
                required: false,
                disabled: true,
            },
            otros_pasivos_circ_operativos: {
                title: false,
                label: 'OTROS PASIVOS CIRC. OPERATIVO',
                type: 'number',
                required: false,
                disabled: true,
            },
            otros_pasivos_circ_no_operativos: {
                title: false,
                label: 'OTROS PASIVOS CIRC. NO OPERATIVO',
                type: 'number',
                required: false,
                disabled: true,
            },
            suma_pasivo_corto_plazo: {
                title: false,
                label: 'SUMA PASIVO CORTO PLAZO',
                type: 'sum',
                negative: false,
                required: false,
                disabled: true,
                items: [
                    'bacomer_corto_plazo',
                    'otros_bancos_corto_plazo',
                    'emesion_deuda_corto_plazo',
                    'porc_circ_largo_plazo_bancomer',
                    'porc_circ_largo_plazo_otros_banc',
                    'proveedores',
                    'impuestos_pagar',
                    'cuentas_pagar_filiales',
                    'gastos_acumulados',
                    'otros_pasivos_circ_operativos',
                    'otros_pasivos_circ_no_operativos',
                ]
            }
        },
        pasivo_largo_plazo: {
            pasivo_largo_plazo: {
                title: true,
                label: 'PASIVO LARGO PLAZO',
                bg: 'bg-section',
                color: 'white',
                colspan: 4
            },
            bancomer_largo_plazo: {
                title: false,
                label: 'BANCOMER LARGO PLAZO',
                type: 'number',
                required: false,
                disabled: true,
            },
            otros_bancos_largo_plazo: {
                title: false,
                label: 'OTROS BANCOS LARGO PLAZO',
                type: 'number',
                required: false,
                disabled: true,
            },
            emisiones_deuda_largo_plazo: {
                title: false,
                label: 'EMISIONES DE DEUDA LARGO PLAZO',
                type: 'number',
                required: false,
                disabled: true,
            },
            cuentas__pagar_filiales_lp: {
                title: false,
                label: 'CTAS X PAGAR FILIALES',
                type: 'number',
                required: false,
                disabled: true,
            },
            otros_pasivos_largo_plazo: {
                title: false,
                label: 'OTROS PASIVOS LARGO PLAZO',
                type: 'number',
                required: false,
                disabled: true,
            },
            primas_antiguedad: {
                title: false,
                label: 'PRIMAS DE ANTIGÜEDAD',
                type: 'number',
                required: false,
                disabled: true,
            },
            pasivos_diferidos: {
                title: false,
                label: 'PASIVOS DIFERIDOS',
                type: 'number',
                required: false,
                disabled: true,
            },
            pasivos_impuesto_diferidos: {
                title: false,
                label: 'PASIVOS X IMPUESTOS DIFERIDOS',
                type: 'number',
                required: false,
                disabled: true,
            },
            suma_pasivo_largo_plazo: {
                title: false,
                label: 'SUMA PASIVO CORTO PLAZO',
                type: 'sum',
                negative: false,
                required: false,
                disabled: true,
                items: [
                    'bancomer_largo_plazo',
                    'otros_bancos_largo_plazo',
                    'emisiones_deuda_largo_plazo',
                    'cuentas__pagar_filiales_lp',
                    'otros_pasivos_largo_plazo',
                    'primas_antiguedad',
                    'pasivos_diferidos',
                    'pasivos_impuesto_diferidos',
                ]
            }
        },
        total_pasivos: {
            total_pasivos: {
                title: false,
                label: 'TOTAL PASIVOS',
                bg: 'bg-title',
                type: 'sum',
                negative: false,
                required: false,
                disabled: true,
                items: [
                    'pasivo_corto_plazo',
                    'pasivo_largo_plazo',
                ]
            }
        },
        capital: {
            capital: {
                title: true,
                label: 'CAPITAL',
                bg: 'bg-section',
                color: 'white',
                colspan: 4
            },
            capital_social_act: {
                title: false,
                label: 'CAPITAL SOCIAL ACTUALIZADO',
                type: 'number',
                required: false,
                disabled: true,
            },
            aportaciones_pendientes_cap: {
                title: false,
                label: 'APORTACIONES PENDIENTES CAPITAL',
                type: 'number',
                required: false,
                disabled: true,
            },
            prima_emisiones_acciones: {
                title: false,
                label: 'PRIMA EN EMISIONES DE ACCIONES',
                type: 'number',
                required: false,
                disabled: true,
            },
            interes_minoritario: {
                title: false,
                label: 'INTERÉS MINORITARIO',
                type: 'number',
                required: false,
                disabled: true,
            },
            exc_actualizacion_capital: {
                title: false,
                label: 'EXC (INSUF) ACTUALIZACIÓN DE CAPITAL',
                type: 'number',
                required: false,
                disabled: true,
            },
            impacto_isr_diferido: {
                title: false,
                label: 'IMPACTO ISR DIFERIDO',
                type: 'number',
                required: false,
                disabled: true,
            },
            reservas: {
                title: false,
                label: 'RESERVAS',
                type: 'number',
                required: false,
                disabled: true,
            },
            utilizades_retenidas: {
                title: false,
                label: 'UTILIDADES RETENIDAS',
                type: 'number',
                required: false,
                disabled: true,
            },
            utilidad_ejercicio: {
                title: false,
                label: 'UTILIDAD DEL EJERCICIO',
                type: 'import',
                required: false,
                disabled: true,
                import: {
                    section: 'resultStatus',
                    key: 'utility_loss_net'
                }
            },
            suma_cap_contable: {
                title: false,
                label: 'SUMA CAPITAL CONTABLE',
                type: 'sum',
                negative: false,
                required: false,
                disabled: true,
                items: [
                    'capital_social_act',
                    'aportaciones_pendientes_cap',
                    'prima_emisiones_acciones',
                    'interes_minoritario',
                    'exc_actualizacion_capital',
                    'impacto_isr_diferido',
                    'reservas',
                    'utilizades_retenidas',
                    'utilidad_ejercicio',
                ]
            }
        },
        suma_pasivo_capital: {
            suma_pasivo_capital: {
                title: false,
                label: 'SUMA PASIVOS + CAPITAL',
                bg: 'bg-title',
                type: 'sum',
                negative: false,
                required: false,
                disabled: true,
                items: [
                    'total_pasivos',
                    'capital',
                ]
            }
        },
        diferencia: {
            diferencia: {
                title: false,
                label: 'DIFERENCIA',
                bg: 'bg-title',
                type: 'sum_sub',
                required: false,
                disabled: true,
                sum: ['total_activos'],
                sub: ['suma_pasivo_capital']
            }
        },

    },
    step2: {
        basic: {
            title: true,
            label: '',
            bg: 'bg-title',
            color: 'white',
            td: ['','','Diferencias 2021-2022','Entrada / Salida']
        },
    },
    conciliation: {
        basic: {
            title: true,
            label: 'CONCILIACIONES DE FLUJO DE EFECTIVO',
            bg: 'bg-title-2',
            color: 'white',
            colspan: 4,
        },
        inv_inventarios: {
            inv_inventarios: {
                title: true,
                simbol: '',
                label: 'Inversión en Inventarios',
                bg: 'bg-title-3',
                color: 'white',
                colspan: 4
            },
            inventario_final: {
                title: false,
                simbol: '',
                label: 'Inventario Final',
                type: 'number',
                required: false,
                disabled: true,
            },
            inventario_inicial: {
                title: false,
                simbol: '-',
                label: 'Inventario Inicial',
                type: 'number',
                required: false,
                disabled: true,
            },
            actualizacion_ejercicio: {
                title: false,
                simbol: '-',
                label: 'Actualización del ejercicio',
                type: 'number',
                required: false,
                disabled: false,
            },
            inversion_neta_inventario: {
                title: false,
                simbol: '=',
                label: 'Inversión neta en Inventarios',
                type: 'number',
                bg: 'bg-section-2',
                required: false,
                disabled: false,
            },
        },
        revaluacion_subsidarias: {
            revaluacion_subsidarias: {
                title: true,
                simbol: '',
                label: 'Revaluación en Subsidiarias y Afiliadas',
                bg: 'bg-title-3',
                color: 'white',
                colspan: 4
            },
            inversion_final_subsidiarias: {
                title: false,
                simbol: '',
                label: 'Inversión Final en Subsidiarias y Afiliadas',
                type: 'number',
                required: false,
                disabled: true,
            },
            inversion_inicial_subsidiarias: {
                title: false,
                simbol: '-',
                label: 'Inversión Inicial en subsidiarias y Afiliadas',
                type: 'number',
                required: false,
                disabled: true,
            },
            inversion_venta_efectivo: {
                title: false,
                simbol: '-',
                label: 'Inversión o Venta en efectivo, Sub y Afil',
                type: 'number',
                required: false,
                disabled: false,
            },
            dividendos_efectivo: {
                title: false,
                simbol: '+',
                label: 'Dividendos en Efectivo',
                type: 'number',
                required: false,
                disabled: false,
            },
            utilidad_perdida_part: {
                title: false,
                simbol: '-',
                label: 'Utilidad o perdida de Part. En Sub y Afil',
                type: 'number',
                required: false,
                disabled: true,
            },
            revaluacion_ejercicio: {
                title: false,
                simbol: '=',
                label: 'Revaluación del Ejercicio',
                type: 'number',
                bg: 'bg-section-2',
                required: false,
                disabled: false,
            },
        },
        inversion_propiedad: {
            inversion_propiedad: {
                title: true,
                simbol: '',
                label: 'Inversión en Propiedad Planta y Equipo',
                bg: 'bg-title-3',
                color: 'white',
                colspan: 4
            },
            prop_planta_eq_final: {
                title: false,
                simbol: '',
                label: 'Propiedad Planta y Equipo Final',
                type: 'number',
                required: false,
                disabled: false,
            },
            prop_planta_eq_incial: {
                title: false,
                simbol: '-',
                label: 'Propiedad Planta y Equipo Inicial',
                type: 'number',
                required: false,
                disabled: false,
            },
            revaluacion_ejercicio_prop: {
                title: false,
                simbol: '-',
                label: 'Revaluación del ejercicio propiedad planta u equipo',
                type: 'number',
                required: false,
                disabled: false,
            },
            depreciacion_ejercicio: {
                title: false,
                simbol: '+',
                label: 'Depreciación del Ejercicio',
                type: 'number',
                required: false,
                disabled: true,
            },
            inversion_neta_prop: {
                title: false,
                simbol: '=',
                label: 'Inversión Neta en Propiedad Planta y Equipo',
                type: 'number',
                bg: 'bg-section-2',
                required: false,
                disabled: false,
            },
        },
        actualizacion_capital: {
            actualizacion_capital: {
                title: true,
                simbol: '',
                label: 'Actualización de Capital',
                bg: 'bg-title-3',
                color: 'white',
                colspan: 4
            },
            capital_contable_final: {
                title: false,
                simbol: '',
                label: 'Capital Contable Final',
                type: 'number',
                required: false,
                disabled: true,
            },
            capital_contable_inicial: {
                title: false,
                simbol: '-',
                label: 'Capital Contable Inicial',
                type: 'number',
                required: false,
                disabled: true,
            },
            aportacion_accionista_efectivo: {
                title: false,
                simbol: '-',
                label: 'Aportación de Accionistas en Efectivo',
                type: 'number',
                required: false,
                disabled: false,
            },
            dividendos_pagados_efectivo: {
                title: false,
                simbol: '+',
                label: 'Dividendos Pagados en efectivo',
                type: 'number',
                required: false,
                disabled: false,
            },
            utilidad_ejercicio: {
                title: false,
                simbol: '-',
                label: 'Utilidad del Ejercicio',
                type: 'number',
                required: false,
                disabled: true,
            },
            actualizacion_ejercicio_ca: {
                title: false,
                simbol: '=',
                label: 'Actualización del Ejercicio',
                type: 'number',
                bg: 'bg-section-2',
                required: false,
                disabled: false,
            },
        },
        actualizacion_resultados: {
            actualizacion_resultados: {
                title: true,
                simbol: '',
                label: 'Actualización de Resultados',
                bg: 'bg-title-3',
                color: 'white',
                colspan: 4
            },
            actualizacion_capital_d: {
                title: false,
                simbol: '',
                label: 'Actualización de Capital',
                type: 'number',
                required: false,
                disabled: false,
            },
            uti_perdida_posicion: {
                title: false,
                simbol: '+',
                label: 'Utilidad o Perdida por posición monetaria',
                type: 'number',
                required: false,
                disabled: true,
            },
            actualizacion_inventarios: {
                title: false,
                simbol: '-',
                label: 'Actualización de Inventarios',
                type: 'number',
                required: false,
                disabled: false,
            },
            revaluacion_inversion_sub: {
                title: false,
                simbol: '-',
                label: 'Revaluación  inversión subsidiarias / Afil',
                type: 'number',
                required: false,
                disabled: false,
            },
            revaluacion_activo_fijo: {
                title: false,
                simbol: '-',
                label: 'Revaluación de Activo Fijo',
                type: 'number',
                required: false,
                disabled: false,
            },
            sum_actualizacion_resultados: {
                title: false,
                simbol: '=',
                label: 'Actualización de Resultados',
                type: 'number',
                bg: 'bg-section-2',
                required: false,
                disabled: false,
            },
        },
        cargos_no_monetarios: {
            cargos_no_monetarios: {
                title: true,
                simbol: '',
                label: 'Cargos no monetarios',
                bg: 'bg-title-3',
                color: 'white',
                colspan: 4
            },
            isr_ptu_activo_diferido: {
                title: false,
                simbol: '+',
                label: 'ISR y PTU Activo Diferido',
                type: 'number',
                required: false,
                disabled: true,
            },
            patentes_licencias: {
                title: false,
                simbol: '+',
                label: 'Patentes Licencias y Marcas',
                type: 'number',
                required: false,
                disabled: true,
            },
            credito_mercantil: {
                title: false,
                simbol: '+',
                label: 'Crédito Mercantil',
                type: 'number',
                required: false,
                disabled: true,
            },
            cargos_diferidos: {
                title: false,
                simbol: '+',
                label: 'Cargos Diferidos',
                type: 'number',
                required: false,
                disabled: true,
            },
            primas_antiguedad: {
                title: false,
                simbol: '+',
                label: 'Primas de Antigüedad',
                type: 'number',
                required: false,
                disabled: true,
            },
            pasivos_diferidos: {
                title: false,
                simbol: '+',
                label: 'Pasivos Diferidos',
                type: 'number',
                required: false,
                disabled: true,
            },
            isr_ptu_pasivos_diferidos: {
                title: false,
                simbol: '+',
                label: 'ISR Y PTU Pasivos Diferidos',
                type: 'number',
                required: false,
                disabled: true,
            },
            actualizacion_resultados_ca: {
                title: false,
                simbol: '+',
                label: 'Actualización en resultados',
                type: 'number',
                required: false,
                disabled: false,
            },
            amortization_ca: {
                title: false,
                simbol: '-',
                label: 'Amortizaciones',
                type: 'number',
                required: false,
                disabled: false,
            },
            cargos_no_monetarios_ca: {
                title: false,
                simbol: '=',
                label: 'Cargos no Monetarios',
                type: 'number',
                bg: 'bg-section-2',
                required: false,
                disabled: false,
            },
        },
        isr_ptu: {
            isr_ptu: {
                title: true,
                simbol: '',
                label: 'ISR y PTU',
                bg: 'bg-title-3',
                color: 'white',
                colspan: 4
            },
            isr_ptu_to: {
                title: false,
                simbol: '',
                label: 'ISR y PTU',
                type: 'number',
                required: false,
                disabled: true,
            },
            isr_ptu_diferido_ejercicio: {
                title: false,
                simbol: '+',
                label: 'ISR y PTU diferido del Ejercicio',
                type: 'number',
                required: false,
                disabled: true,
            },
            financiamiento_fiscal: {
                title: false,
                simbol: '+',
                label: 'Financiamiento Fiscal',
                type: 'number',
                required: false,
                disabled: false,
            },
            isr_ptu_diferido_pasivo: {
                title: false,
                simbol: '+',
                label: 'ISR y PTU diferido Pasivo',
                type: 'number',
                required: false,
                disabled: true,
            },
            isr_ptu_diferido_activo: {
                title: false,
                simbol: '-',
                label: 'ISR Y PTU Diferido Activo',
                type: 'sum',
                negative: false,
                required: false,
                disabled: true,
                items: [
                    {
                        page: 'step1',
                        section: 'pasivo_corto_plazo',
                        key: 'impuestos_pagar',
                        period: 'entrance_exit'
                    }
                ]
            },
            isr_ptu_impac_pagados: {
                title: false,
                simbol: '=',
                label: 'ISR, PTU e impac pagados en efectivo',
                type: 'number',
                bg: 'bg-section-2',
                required: false,
                disabled: false,
            },
        },
        perdida_utilidad_cambiaria: {
            perdida_utilidad_cambiaria: {
                title: true,
                simbol: '',
                label: 'Perdida o Utilidad Cambiaria',
                bg: 'bg-title-3',
                color: 'white',
                colspan: 4
            },
            perdida_utilidad_cam_ejer: {
                title: false,
                simbol: '',
                label: 'Pérdida o Utilidad Cambiaria del Ejercicio',
                type: 'number',
                required: false,
                disabled: true,
            },
            incluida_deuda_cor_pla: {
                title: false,
                simbol: '-',
                label: 'Incluida en la deuda a Corto Plazo',
                type: 'number',
                required: false,
                disabled: false,
            },
            incluida_deuda_lar_pla: {
                title: false,
                simbol: '-',
                label: 'Incluida en la deuda a Largo Plazo',
                type: 'number',
                required: false,
                disabled: false,
            },
            incluida_clientes: {
                title: false,
                simbol: '+',
                label: 'Incluida en Clientes',
                type: 'number',
                required: false,
                disabled: false,
            },
            incluida_inventarios: {
                title: false,
                simbol: '+',
                label: 'Incluida en Inventarios',
                type: 'number',
                required: false,
                disabled: false,
            },
            incluida_proveedores: {
                title: false,
                simbol: '-',
                label: 'Incluida en proveedores',
                type: 'number',
                required: false,
                disabled: false,
            },
            perdida_utilidad_cam_pag: {
                title: false,
                simbol: '=',
                label: 'Pérdida o Utilidad Cambiaria Pagada',
                type: 'number',
                bg: 'bg-section-2',
                required: false,
                disabled: false,
            },
        },
    },
    cash_flow: {
        basic: {
            title: true,
            label: 'ESTADO DE FLUJO DE EFECTIVO',
            bg: 'bg-title-2',
            color: 'white',
            colspan: 4,
        },
        cells: {
            title: true,
            label: 'PERIODO',
            color: 'white',
            td: ['2022']
        },
        uafir: {
            title: false,
            label: 'U A F I R',
            type: 'number',
            bg: 'bg-title-2',
            required: false,
            disabled: true,
        },
        depreciacion: {
            title: false,
            label: 'DEPRECIACIÓN',
            type: 'number',
            required: false,
            disabled: true,
        },
        amortizaciones: {
            title: false,
            label: 'AMORTIZACIONES',
            type: 'number',
            required: false,
            disabled: true,
        },
        result_extra_no_mone: {
            title: false,
            label: 'RESULTADO EXTRAORDINARIO NO MONETARIO',
            type: 'number',
            required: false,
            disabled: false,
        },
        otros_gastos_igre_no_mone: {
            title: false,
            label: 'OTROS GASTOS / INGRESOS NO MONETARIO',
            type: 'number',
            required: false,
            disabled: true,
        },
        cargos_no_monetarios: {
            title: false,
            label: 'CARGOS NO MONETARIOS ',
            type: 'sum',
            required: false,
            disabled: true,
            items: [
                {
                    page: 'conciliation',
                    section: 'cargos_no_monetarios',
                    key: 'cargos_no_monetarios_ca',
                    period: 'only',
                    
                }
            ]
        },
        flujo_bruto: {
            title: false,
            label: 'FLUJO BRUTO',
            type: 'sum',
            negative: false,
            bg: 'bg-title-2',
            required: false,
            disabled: true,
            items: [
                'uafir',
                'depreciacion',
                'amortizaciones',
                'result_extra_no_mone',
                'otros_gastos_igre_no_mone',
                'cargos_no_monetarios',
            ]
        },
        variacion_client_doc_cobrar: {
            title: false,
            label: 'VARIACIÓN CLIENTES Y DOCTOS POR COBRAR',
            type: 'sum',
            negative: false,
            required: false,
            disabled: true,
            items: [
                {
                    page: 'step1',
                    section: 'activos_circulantes',
                    key: 'clientes_docs_cobrar',
                    period: 'entrance_exit',
                    
                }
            ]
        },
        variacion_inventario: {
            title: false,
            label: 'VARIACIÓN INVENTARIOS',
            type: 'sum',
            negative: true,
            required: false,
            disabled: true,
            items: [
                {
                    page: 'conciliation',
                    section: 'inv_inventarios',
                    key: 'inversion_neta_inventario',
                    period: 'only',
                    
                }
            ]
        },
        varia_ctas_cobrar_afi: {
            title: false,
            label: 'VARIACIÓN CTAS X COBRAR AFILIADAS CP',
            type: 'sum',
            negative: false,
            required: false,
            disabled: true,
            items: [
                {
                    page: 'step1',
                    section: 'activos_circulantes',
                    key: 'cuentas_cobrar_filiales',
                    period: 'entrance_exit',
                    
                }
            ]
        },
        variacion_pagos_anticipados: {
            title: false,
            label: 'VARIACIÓN PAGOS ANTICIPADOS / AFILIADAS',
            type: 'sum',
            negative: false,
            required: false,
            disabled: true,
            items: [
                {
                    page: 'step1',
                    section: 'activos_circulantes',
                    key: 'pagos_anticipados',
                    period: 'entrance_exit',
                    
                }
            ]
        },
        variacion_otros_activos: {
            title: false,
            label: 'VARIACIÓN OTROS ACTIVOS CIRC. OPERATIVOS',
            type: 'sum',
            negative: false,
            required: false,
            disabled: true,
            items: [
                {
                    page: 'step1',
                    section: 'activos_circulantes',
                    key: 'otros_act_circ_operativos',
                    period: 'entrance_exit',
                    
                }
            ]
        },
        variacion_proveedores: {
            title: false,
            label: 'VARIACIÓN PROVEEDORES',
            type: 'sum',
            negative: false,
            required: false,
            disabled: true,
            items: [
                {
                    page: 'step1',
                    section: 'pasivo_corto_plazo',
                    key: 'proveedores',
                    period: 'entrance_exit',
                    
                }
            ]
        },
        varia_ctas_pagar_afi: {
            title: false,
            label: 'VARIACIÓN CTAS X PAGAR AFILIADAS CP',
            type: 'sum',
            negative: false,
            required: false,
            disabled: true,
            items: [
                {
                    page: 'step1',
                    section: 'pasivo_corto_plazo',
                    key: 'cuentas_pagar_filiales',
                    period: 'entrance_exit',
                    
                }
            ]
        },
        varia_gatos_acumulados: {
            title: false,
            label: 'VARIACIÓN GASTOS ACUMULADOS / AFILIADAS',
            type: 'sum',
            negative: false,
            required: false,
            disabled: true,
            items: [
                {
                    page: 'step1',
                    section: 'pasivo_corto_plazo',
                    key: 'gastos_acumulados',
                    period: 'entrance_exit',
                    
                }
            ]
        },
        variacion_otros_pasivos: {
            title: false,
            label: 'VARIACIÓN OTROS PASIVOS CIRC. OPERATIVOS',
            type: 'sum',
            negative: false,
            required: false,
            disabled: true,
            items: [
                {
                    page: 'step1',
                    section: 'pasivo_corto_plazo',
                    key: 'otros_pasivos_circ_operativos',
                    period: 'entrance_exit',
                    
                }
            ]
        },
        capital_trabajo_neto: {
            title: false,
            label: 'CAPITAL DE TRABAJO NETO',
            type: 'sum',
            negative: false,
            bg: 'bg-title-2',
            required: false,
            disabled: true,
            items: [
                'variacion_client_doc_cobrar',
                'variacion_inventario',
                'varia_ctas_cobrar_afi',
                'variacion_pagos_anticipados',
                'variacion_otros_activos',
                'variacion_proveedores',
                'varia_ctas_pagar_afi',
                'varia_gatos_acumulados',
                'variacion_otros_pasivos',
            ]
        },
        flujo_operacion: {
            title: false,
            label: 'FLUJO DE OPERACIÓN',
            type: 'sum',
            negative: false,
            bg: 'bg-title-2',
            required: false,
            disabled: true,
            items: [
                'flujo_bruto',
                'capital_trabajo_neto'
            ]
        },
        impuestos_pagados: {
            title: false,
            label: 'IMPUESTOS PAGADOS',
            type: 'sum',
            negative: false,
            required: false,
            disabled: true,
            items: [
                {
                    page: 'conciliation',
                    section: 'isr_ptu',
                    key: 'isr_ptu_impac_pagados',
                    period: 'only',
                    
                }
            ]
        },
        otros_gastos_ingresos: {
            title: false,
            label: 'OTROS GASTOS O INGRESOS',
            type: 'number',
            required: false,
            disabled: true,
        },
        result_extra_efectivo: {
            title: false,
            label: 'RESULTADO EXTRAORDINARIO EN EFECTIVO',
            type: 'sum',
            negative: false,
            required: false,
            disabled: true,
            items: [
                'impuestos_pagados',
                'otros_gastos_ingresos'
            ]
        },
        flujo_neto: {
            title: false,
            label: 'FLUJO NETO',
            type: 'sum_sub',
            bg: 'bg-title-2',
            required: false,
            disabled: true,
            sum: [
                'flujo_operacion',
            ],
            sub: [
                'result_extra_efectivo'
            ]
        },
        gastos_financieros_pagados: {
            title: false,
            label: 'GASTOS FINANCIEROS PAGADOS',
            type: 'number',
            required: false,
            disabled: true,
        },
        perdida_utilidad_cambiaria_pag: {
            title: false,
            label: 'PERDIDA O UTILIDAD CAMBIARIA PAGADA',
            type: 'number',
            required: false,
            disabled: true,
        },
        productos_financieros: {
            title: false,
            label: 'PRODUCTOS FINANCIEROS',
            type: 'number',
            required: false,
            disabled: true,
        },
        compromisos_financieros: {
            title: false,
            label: 'COMPROMISOS FINANCIEROS',
            type: 'sum',
            negative: false,
            required: false,
            disabled: true,
            items: [
                'gastos_financieros_pagados',
                'perdida_utilidad_cambiaria_pag',
                'productos_financieros',
            ]
        },
        flujo_antes_inversion: {
            title: false,
            label: 'FLUJO ANTES DE INVERSIONES',
            type: 'sum',
            negative: false,
            bg: 'bg-title-2',
            required: false,
            disabled: true,
            items: [
                'flujo_neto',
                'compromisos_financieros',
            ]
        },
        variacion_neta_prop_pta_equi: {
            title: false,
            label: 'VARIACIÓN NETA EN PROP. PTA. Y EQUIPO',
            type: 'sum',
            negative: true,
            required: false,
            disabled: true,
            items: [
                {
                    page: 'conciliation',
                    section: 'inversion_propiedad',
                    key: 'inversion_neta_prop',
                    period: 'only',
                }
            ]
        },
        variacion_sub_afi: {
            title: false,
            label: 'VARIACIÓN EN SUBS./AFILS.',
            type: 'sum',
            negative: false,
            required: false,
            disabled: true,
            items: [
                {
                    page: 'step1',
                    section: 'activo_fijo',
                    key: 'inversion_subsidiarias',
                    period: 'entrance_exit',
                }
            ]
        },
        dividendos_recibidos_efectivo: {
            title: false,
            label: 'DIVIDENDOS RECIBIDOS EN EFECTIVO DE SUBS./AFILS.',
            type: 'number',
            required: false,
            disabled: false,
        },
        variacion_deudores_diversos: {
            title: false,
            label: 'VARIACIÓN DEUDORES DIVERSOS',
            type: 'sum',
            negative: false,
            required: false,
            disabled: true,
            items: [
                {
                    page: 'step1',
                    section: 'activos_circulantes',
                    key: 'deudores_diversos',
                    period: 'entrance_exit',
                }
            ]
        },
        variacion_otros_activos_no_ope: {
            title: false,
            label: 'VARIACIÓN OTROS ACTIVOS NO OPERATIVOS',
            type: 'sum',
            negative: false,
            required: false,
            disabled: true,
            items: [
                {
                    page: 'step1',
                    section: 'activo_fijo',
                    key: 'otros_activos_fijos',
                    period: 'entrance_exit',
                }
            ]
        },
        variacion_patentes_licencias: {
            title: false,
            label: 'VARIACIÓN PATENTES, LICENCIAS Y MARCAS',
            type: 'sum',
            negative: false,
            required: false,
            disabled: true,
            items: [
                {
                    page: 'step1',
                    section: 'otros_activos',
                    key: 'patentes_licencias_marcas',
                    period: 'entrance_exit',
                }
            ]
        },
        variacion_credito_mercantil: {
            title: false,
            label: 'VARIACIÓN EN CRÉDITO MERCANTIL',
            type: 'sum',
            negative: false,
            required: false,
            disabled: true,
            items: [
                {
                    page: 'step1',
                    section: 'otros_activos',
                    key: 'credito_mercantil',
                    period: 'entrance_exit',
                }
            ]
        },
        variacion_cargos_diferidos: {
            title: false,
            label: 'VARIACIÓN EN CARGOS DIFERIDOS',
            type: 'sum',
            negative: false,
            required: false,
            disabled: true,
            items: [
                {
                    page: 'step1',
                    section: 'otros_activos',
                    key: 'cargos_diferidos',
                    period: 'entrance_exit',
                }
            ]
        },
        variacion_pasivos_diferidos: {
            title: false,
            label: 'VARIACIÓN EN PASIVOS DIFERIDOS',
            type: 'sum',
            negative: false,
            required: false,
            disabled: true,
            items: [
                {
                    page: 'step1',
                    section: 'pasivo_largo_plazo',
                    key: 'pasivos_diferidos',
                    period: 'entrance_exit',
                }
            ]
        },
        variacion_otros_pasivos_no_ope: {
            title: false,
            label: 'VARIACIÓN OTROS PASIVOS NO OPERATIVOS',
            type: 'sum',
            negative: false,
            required: false,
            disabled: true,
            items: [
                {
                    page: 'step1',
                    section: 'pasivo_corto_plazo',
                    key: 'otros_pasivos_circ_no_operativos',
                    period: 'entrance_exit',
                }
            ]
        },
        dividendos_pagados_efectivo: {
            title: false,
            label: 'DIVIDENDOS PAGADOS EN EFECTIVO',
            type: 'sum',
            negative: false,
            required: false,
            disabled: true,
            items: [
                {
                    page: 'conciliation',
                    section: 'actualizacion_capital',
                    key: 'dividendos_pagados_efectivo',
                    period: 'only',
                }
            ]
        },
        pago_prima_antiguedad: {
            title: false,
            label: 'PAGO PRIMAS DE ANTIGÜEDAD',
            type: 'sum',
            negative: false,
            required: false,
            disabled: true,
            items: [
                {
                    page: 'step1',
                    section: 'pasivo_largo_plazo',
                    key: 'primas_antiguedad',
                    period: 'entrance_exit',
                }
            ]
        },
        total_inversiones: {
            title: false,
            label: 'TOTAL DE INVERSIONES',
            type: 'sum',
            negative: false,
            required: false,
            disabled: true,
            items: [
                'variacion_neta_prop_pta_equi',
                'variacion_sub_afi',
                'dividendos_recibidos_efectivo',
                'variacion_deudores_diversos',
                'variacion_otros_activos_no_ope',
                'variacion_patentes_licencias',
                'variacion_credito_mercantil',
                'variacion_cargos_diferidos',
                'variacion_pasivos_diferidos',
                'variacion_otros_pasivos_no_ope',
                'dividendos_pagados_efectivo',
                'pago_prima_antiguedad',
            ]
        },
        flujo_necesario_excedentes: {
            title: false,
            label: 'FLUJO NECESARIO O EXCEDENTE',
            type: 'sum',
            negative: false,
            bg: 'bg-title-2',
            required: false,
            disabled: true,
            items: [
                'flujo_antes_inversion',
                'total_inversiones'
            ]
        },
        porcion_circ_deuda_largo_pla: {
            title: false,
            label: 'PORCIÓN CIRC. DEUDA LARGO PLAZO',
            type: 'sum',
            negative: false,
            required: false,
            disabled: true,
            items: [
                {
                    page: 'step1',
                    section: 'pasivo_corto_plazo',
                    key: 'porc_circ_largo_plazo_bancomer',
                    period: 'entrance_exit',
                },
                {
                    page: 'step1',
                    section: 'pasivo_corto_plazo',
                    key: 'porc_circ_largo_plazo_otros_banc',
                    period: 'entrance_exit',
                }
            ]
        },
        variacion_deuda_cor_plazo: {
            title: false,
            label: 'VARIACIÓN DEUDA CORTO PLAZO',
            type: 'sum',
            negative: false,
            required: false,
            disabled: true,
            items: [
                {
                    page: 'step1',
                    section: 'pasivo_corto_plazo',
                    key: 'bacomer_corto_plazo',
                    period: 'entrance_exit',
                },
                {
                    page: 'step1',
                    section: 'pasivo_corto_plazo',
                    key: 'otros_bancos_corto_plazo',
                    period: 'entrance_exit',
                }
            ]
        },
        variacion_deuda_lar_plazo: {
            title: false,
            label: 'VARIACIÓN DEUDA LARGO PLAZO',
            type: 'sum',
            negative: false,
            required: false,
            disabled: true,
            items: [
                {
                    page: 'step1',
                    section: 'pasivo_largo_plazo',
                    key: 'bancomer_largo_plazo',
                    period: 'entrance_exit',
                },
                {
                    page: 'step1',
                    section: 'pasivo_largo_plazo',
                    key: 'otros_bancos_largo_plazo',
                    period: 'entrance_exit',
                }
            ]
        },
        variacion_emision_deuda_cor_plazo: {
            title: false,
            label: 'VARIACIÓN EMISIÓN DE DEUDA CORTO PLAZO',
            type: 'sum',
            negative: false,
            required: false,
            disabled: true,
            items: [
                {
                    page: 'step1',
                    section: 'pasivo_corto_plazo',
                    key: 'emesion_deuda_corto_plazo',
                    period: 'entrance_exit',
                }
            ]
        },
        variacion_emision_deuda_lar_plazo: {
            title: false,
            label: 'VARIACIÓN EMISIÓN DE DEUDA LARGO PLAZO',
            type: 'sum',
            negative: false,
            required: false,
            disabled: true,
            items: [
                {
                    page: 'step1',
                    section: 'pasivo_largo_plazo',
                    key: 'emisiones_deuda_largo_plazo',
                    period: 'entrance_exit',
                }
            ]
        },
        variacion_ctas_cobrar_lar_plazo: {
            title: false,
            label: 'VARIACIÓN CTAS X  COBRAR FILIALES LARGO PLAZO',
            type: 'sum',
            negative: false,
            required: false,
            disabled: true,
            items: [
                {
                    page: 'step1',
                    section: 'activo_fijo',
                    key: 'cuentas_cobrar_filiales_fijo',
                    period: 'entrance_exit',
                }
            ]
        },
        variacion_ctas_pagar_lar_plazo: {
            title: false,
            label: 'VARIACIÓN CTAS X PAGAR FILIALES LARGO PLAZO',
            type: 'sum',
            negative: false,
            required: false,
            disabled: true,
            items: [
                {
                    page: 'step1',
                    section: 'pasivo_largo_plazo',
                    key: 'cuentas__pagar_filiales_lp',
                    period: 'entrance_exit',
                }
            ]
        },
        aportacion_accionista_efectivo: {
            title: false,
            label: 'APORTACIÓN DE ACCIONISTAS EN EFECTIVO',
            type: 'sum',
            negative: false,
            required: false,
            disabled: true,
            items: [
                {
                    page: 'conciliation',
                    section: 'actualizacion_capital',
                    key: 'aportacion_accionista_efectivo',
                    period: 'only',
                }
            ]
        },
        total_financiamiento: {
            title: false,
            label: 'TOTAL FINANCIAMIENTO',
            type: 'sum',
            negative: false,
            required: false,
            disabled: true,
            items: [
                'porcion_circ_deuda_largo_pla',
                'variacion_deuda_cor_plazo',
                'variacion_deuda_lar_plazo',
                'variacion_emision_deuda_cor_plazo',
                'variacion_emision_deuda_lar_plazo',
                'variacion_ctas_cobrar_lar_plazo',
                'variacion_ctas_pagar_lar_plazo',
                'aportacion_accionista_efectivo',
            ]
        },
        aumento_disminucion_caja: {
            title: false,
            label: 'AUMENTO O DISMINUCIÓN EN CAJA',
            type: 'sum',
            negative: false,
            bg: 'bg-title-2',
            required: false,
            disabled: true,
            items: [
                'flujo_necesario_excedentes',
                'total_financiamiento'
            ]
        },
        variacion_caja_flujo: {
            title: false,
            label: 'VAR. EN CAJA FLUJO - VAR. EN CAJA BALANCE',
            type: 'sum_sub',
            bg: 'bg-title-2',
            required: false,
            disabled: true,
            sum: [
                {
                    page: 'step1',
                    section: 'activos_circulantes',
                    key: 'caja_banco',
                    period: 'diff',
                    
                }
            ],
            sub: ['aumento_disminucion_caja']
        },

    },
    qualitative: {
        basic: {
            title: false,
            label: 'JUSTIFIQUE SUS RESPUESTAS',
            type: 'textarea',
            require: false,
            disabled: false,
        }
    }
}