<script>
import { mapGetters } from 'vuex'
export default {
    name: "FormPractice",
    data() {
        return {
            menuNav: [
                {
                    label: "Instrucciones",
                    slug: 'instructions',
                    active: true
                },
                {
                    label: "Estado de Resultados",
                    slug: 'result_status',
                    active: false
                },
                {
                    label: "Cálculo de diferencias",
                    slug: 'step1',
                    active: false
                },
                {
                    label: "Clasificación de uso-aplicación",
                    slug: 'step2',
                    active: false
                },
                {
                    label: "Conciliaciones",
                    slug: 'conciliation',
                    active: false
                },
                {
                    label: "Flujo de Efectivo Final",
                    slug: 'ending_cash_flow',
                    active: false
                }
            ],
            slugSelected: 'instructions',
            periods: null,
        }
    },
    computed: {
        rowsBasic() {
            return Object.keys(this.config.basic);
        },
        ...mapGetters({
            'config': 'practices/config',
            'periodsResultsStatus': 'practices/periodsResultsStatus',
        })
    },
    created(){
        Event.$on('event-asign-periods', period => {
            this.periods = period;
        });
    },
    async mounted(){
        if(!this.periods){
            this.periods = await this.periodsResultsStatus;
        }
    },
    methods: {
        setActive(menu) {
            console.log(menu.slug);
            this.menuNav.forEach(m => {
                m.active = false;
            })
            menu.active = true;
            this.slugSelected = menu.slug;
            Event.$emit('event-save-scorm', this.periods);
        },
        sumCells(dataSet, items, key, negative = false/* , period, config */){
            let total = 0;
            if(items && items.length){
                items.forEach(item => {
                    if(typeof item == 'object'){
                        total += Number(this.periods[item.page][item.period][item.section][item.key]);
                    }else{
                        total += Number(dataSet[item])
                    }
                })
            }
            if(negative){
                total = -total;
            }
            dataSet[key] = total;
            return total;
        },
        sumCellsTotal(dataSet, items, key, config /* , period,  */){
            //console.log(dataSet);
            /* console.log(items);
            console.log(sum_pc); */
            let total = 0;
            if(items && items.length){
                items.forEach(item => {
                    if(typeof dataSet[item] === 'number'){
                        total += dataSet[item];
                    }else{
                        for(const ite in dataSet[item]){
                            if(config[item][ite].type == 'sum'){
                                total += Number(dataSet[item][ite])
                            }
    
                        }
                    }
                })
            }
            dataSet[key] = total;
            return total;
        },
        subCellsTotal(dataSet, sum = [], sub = [], key, config /* , period,  */){
            // console.log(dataSet);
            //console.log(config);
            let sumaTotal = 0;
            let subTotal = 0;
            let total = 0;
            if(sum && sum.length){
                sum.forEach(item => {
                    if(typeof dataSet[item] === 'number'){
                        sumaTotal += dataSet[item];
                    }else{
                        for(const ite in dataSet[item]){
                            if(config[item][ite].type == 'sum'){
                                sumaTotal += Number(dataSet[item][ite])
                            }
    
                        }
                    }
                })
            }
            if(sub && sub.length){
                sub.forEach(item => {
                    if(typeof dataSet[item] === 'number'){
                        subTotal += dataSet[item];
                    }else{
                        for(const ite in dataSet[item]){
                            if(config[item][ite].type == 'sum'){
                                subTotal += Number(dataSet[item][ite])
                            }
    
                        }
                    }
                })
            }
            total = sumaTotal - subTotal;
            dataSet[key] = total;
            return total;
        },
        subCells(dataSet, sum = [], sub = [], key /* period,  config */){
            let total = 0;
            let sumTotal = 0;
            let subTotal = 0;
            if(sum && sum.length){
                sum.forEach(item => {
                    if(typeof item == 'object'){
                        sumTotal += Number(this.periods[item.page][item.period][item.section][item.key]);
                    }else{
                        sumTotal += Number(dataSet[item])
                    }
                })
            }

            if(sub && sub.length){
                sub.forEach(item => {
                    if(typeof item == 'object'){
                        subTotal += Number(this.periods[item.page][item.period][item.section][item.key]);
                    }else{
                        subTotal += Number(dataSet[item])
                    }
                })
            }
            total = sumTotal - subTotal;
            dataSet[key] = total;
            return total;
        },

        importCellsFlow(dataSet, dataImport){
            console.log(dataSet);
            console.log(dataImport);
        },

        importCells(dataSet, dataSetImport, key){
            /* console.log(dataSet);
            console.log(dataSetImport);
            console.log(key); */
            let total = 0;
            total = dataSetImport
            dataSet[key] = total;
            return total;
        },

        subRow(dataSet, key, _key){
            let total = 0;
            let period_before = null;
            for(const period in dataSet){
                if(period !== 'diff'){
                    if(period_before !== null){
                        total = dataSet[period][key][_key] - dataSet[period_before][key][_key]
                        dataSet.diff[key][_key] = total;
                    }else{
                        period_before = period
                    }
                }
            }
            return total;
        },

        async finishExamen(){
            await this.createFrameTable();
            const diff = this.periods.answer_correct.diff_caja;
            const diff_ev = this.periods.answer_correct.diff_evaluar;
            const variacion_caja = this.periods.answer_correct.variacion_caja_flujo;
            const var_ev = this.periods.answer_correct.variacion_evaluar;
            // Evaluar si la respuesta de la diferencia es correcta
            // console.log(this.periods[diff_ev.page][diff_ev.period][diff_ev.section][diff_ev.key]);
            if(this.periods.qualitative.basic != ''){
                let totalPoints = 0;
                const pointsPhrase = await this.verifyPhrases();
                totalPoints = totalPoints + pointsPhrase;
                if(diff == this.periods[diff_ev.page][diff_ev.period][diff_ev.section][diff_ev.key]
                && variacion_caja == this.periods[var_ev.page][var_ev.period][var_ev.key]){
                    // console.log('Correcto');
                    totalPoints = totalPoints + 20;
                    Event.$emit("event-result-practice", totalPoints);

                }else{
                    Event.$emit("event-result-practice", totalPoints);
                    // console.log('Incorrecto');
                }
            }else{
                this.$notify({
                    group: 'noti',
                    type: 'error',
                    title: 'Es necesario una respuesta',
                    text: 'Es necesario que justifique la respuesta'
                });
            }
        },

        verifyPhrases(){
            let points = 0;
            const paragraph = this.periods.qualitative.basic.toUpperCase();
            const phrases = this.periods.answer_correct.phrases;
            phrases.forEach(phrase => {
                if(paragraph.includes(phrase)){
                    points += 2;
                }
            });
            return points;
        },
        createFrameTable(){
            //console.log('enviando info');
            const tableComplete = [];
            let headerPrincipal = {text: 'EXAMEN PRACTICO', style: 'header'}
            tableComplete.push(headerPrincipal);
            // Estado de resultados
            let subHeaderRS = { text: 'Estado de Resultados', style: 'header'};
            tableComplete.push(subHeaderRS);
            const RS = this.config.results_status;
            let rT = [];
            for(const key in RS){
                if(RS[key].title === true){
                    rT.push([
                        {text: RS[key].label, style: 'HeadT'},
                        {text: '2021', style: 'HeadT'},
                        {text: '2022', style: 'HeadT'},
                    ]);
                }else{
                    let row = [];
                    row.push({text: RS[key].label, style: 'Rsuccess'});
                    for(const _key in this.periods.resultStatus){
                        const _obj = {text: '$' + this.periods.resultStatus[_key][key], style: ''}
                        row.push(_obj);
                    }
                    rT.push(row);
                }

            }
            let objRS = {
                table: {
                    headerRows: 1,
                    widths: ['*', '*', '*'],
                    body: rT
                }
            }
            tableComplete.push(objRS);
            // Seccion paso 1 y paso 2
            let subHeaderST12 = { text: 'PASO 1 y PASO 2', style: 'header'};
            tableComplete.push(subHeaderST12);
            const ST12 = this.config.step1;
            rT = [];
            rT.push([
                {text: ST12.basic.label, style: 'HeadT'},
                {text: '2021', style: 'HeadT'},
                {text: '2022', style: 'HeadT'},
                {text: 'Diferencia 2021-2022', style: 'HeadT'},
                {text: 'Entrada/Salida', style: 'HeadT'},
            ])
            for(const key in ST12.activos_circulantes){
                if(ST12.activos_circulantes[key].title === true){
                    rT.push([
                        {text: ST12.activos_circulantes[key].label, style: 'HeadT'},
                        {text: '', style: 'HeadT'},
                        {text: '', style: 'HeadT'},
                        {text: '', style: 'HeadT'},
                        {text: '', style: 'HeadT'},
                    ]);
                }else{
                    let row = [];
                    row.push({text: ST12.activos_circulantes[key].label, style: 'Rsuccess'});
                    for(const _key in this.periods.step1){
                        const _obj = {text: '$' + this.periods.step1[_key].activos_circulantes[key], style: ''}
                        row.push(_obj);
                    }
                    rT.push(row);
                }

            }
            for(const key in ST12.activo_fijo){
                if(ST12.activo_fijo[key].title === true){
                    rT.push([
                        {text: ST12.activo_fijo[key].label, style: 'HeadT'},
                        {text: '', style: 'HeadT'},
                        {text: '', style: 'HeadT'},
                        {text: '', style: 'HeadT'},
                        {text: '', style: 'HeadT'},
                    ]);
                }else{
                    let row = [];
                    row.push({text: ST12.activo_fijo[key].label, style: 'Rsuccess'});
                    for(const _key in this.periods.step1){
                        const _obj = {text: '$' + this.periods.step1[_key].activo_fijo[key], style: ''}
                        row.push(_obj);
                    }
                    rT.push(row);
                }

            }
            for(const key in ST12.otros_activos){
                if(ST12.otros_activos[key].title === true){
                    rT.push([
                        {text: ST12.otros_activos[key].label, style: 'HeadT'},
                        {text: '', style: 'HeadT'},
                        {text: '', style: 'HeadT'},
                        {text: '', style: 'HeadT'},
                        {text: '', style: 'HeadT'},
                    ]);
                }else{
                    let row = [];
                    row.push({text: ST12.otros_activos[key].label, style: 'Rsuccess'});
                    for(const _key in this.periods.step1){
                        const _obj = {text: '$' + this.periods.step1[_key].otros_activos[key], style: ''}
                        row.push(_obj);
                    }
                    rT.push(row);
                }
            }
            for(const key in ST12.total_activos){
                if(ST12.total_activos[key].title === true){
                    rT.push([
                        {text: ST12.total_activos[key].label, style: 'HeadT'},
                        {text: '', style: 'HeadT'},
                        {text: '', style: 'HeadT'},
                        {text: '', style: 'HeadT'},
                        {text: '', style: 'HeadT'},
                    ]);
                }else{
                    let row = [];
                    row.push({text: ST12.total_activos[key].label, style: 'Rsuccess'});
                    for(const _key in this.periods.step1){
                        const _obj = {text: '$' + this.periods.step1[_key][key], style: ''}
                        row.push(_obj);
                    }
                    rT.push(row);
                }
            }
            for(const key in ST12.pasivo_corto_plazo){
                if(ST12.pasivo_corto_plazo[key].title === true){
                    rT.push([
                        {text: ST12.pasivo_corto_plazo[key].label, style: 'HeadT'},
                        {text: '', style: 'HeadT'},
                        {text: '', style: 'HeadT'},
                        {text: '', style: 'HeadT'},
                        {text: '', style: 'HeadT'},
                    ]);
                }else{
                    let row = [];
                    row.push({text: ST12.pasivo_corto_plazo[key].label, style: 'Rsuccess'});
                    for(const _key in this.periods.step1){
                        const _obj = {text: '$' + this.periods.step1[_key].pasivo_corto_plazo[key], style: ''}
                        row.push(_obj);
                    }
                    rT.push(row);
                }
            }
            for(const key in ST12.pasivo_largo_plazo){
                if(ST12.pasivo_largo_plazo[key].title === true){
                    rT.push([
                        {text: ST12.pasivo_largo_plazo[key].label, style: 'HeadT'},
                        {text: '', style: 'HeadT'},
                        {text: '', style: 'HeadT'},
                        {text: '', style: 'HeadT'},
                        {text: '', style: 'HeadT'},
                    ]);
                }else{
                    let row = [];
                    row.push({text: ST12.pasivo_largo_plazo[key].label, style: 'Rsuccess'});
                    for(const _key in this.periods.step1){
                        const _obj = {text: '$' + this.periods.step1[_key].pasivo_largo_plazo[key], style: ''}
                        row.push(_obj);
                    }
                    rT.push(row);
                }
            }
            for(const key in ST12.total_pasivos){
                if(ST12.total_pasivos[key].title === true){
                    rT.push([
                        {text: ST12.total_pasivos[key].label, style: 'HeadT'},
                        {text: '', style: 'HeadT'},
                        {text: '', style: 'HeadT'},
                        {text: '', style: 'HeadT'},
                        {text: '', style: 'HeadT'},
                    ]);
                }else{
                    let row = [];
                    row.push({text: ST12.total_pasivos[key].label, style: 'Rsuccess'});
                    for(const _key in this.periods.step1){
                        const _obj = {text: '$' + this.periods.step1[_key][key], style: ''}
                        row.push(_obj);
                    }
                    rT.push(row);
                }
            }
            for(const key in ST12.capital){
                if(ST12.capital[key].title === true){
                    rT.push([
                        {text: ST12.capital[key].label, style: 'HeadT'},
                        {text: '', style: 'HeadT'},
                        {text: '', style: 'HeadT'},
                        {text: '', style: 'HeadT'},
                        {text: '', style: 'HeadT'},
                    ]);
                }else{
                    let row = [];
                    row.push({text: ST12.capital[key].label, style: 'Rsuccess'});
                    for(const _key in this.periods.step1){
                        const _obj = {text: '$' + this.periods.step1[_key].capital[key], style: ''}
                        row.push(_obj);
                    }
                    rT.push(row);
                }
            }
            for(const key in ST12.suma_pasivo_capital){
                if(ST12.suma_pasivo_capital[key].title === true){
                    rT.push([
                        {text: ST12.suma_pasivo_capital[key].label, style: 'HeadT'},
                        {text: '', style: 'HeadT'},
                        {text: '', style: 'HeadT'},
                        {text: '', style: 'HeadT'},
                        {text: '', style: 'HeadT'},
                    ]);
                }else{
                    let row = [];
                    row.push({text: ST12.suma_pasivo_capital[key].label, style: 'Rsuccess'});
                    for(const _key in this.periods.step1){
                        const _obj = {text: '$' + this.periods.step1[_key][key], style: ''}
                        row.push(_obj);
                    }
                    rT.push(row);
                }
            }
            for(const key in ST12.diferencia){
                if(ST12.diferencia[key].title === true){
                    rT.push([
                        {text: ST12.diferencia[key].label, style: 'HeadT'},
                        {text: '', style: 'HeadT'},
                        {text: '', style: 'HeadT'},
                        {text: '', style: 'HeadT'},
                        {text: '', style: 'HeadT'},
                    ]);
                }else{
                    let row = [];
                    row.push({text: ST12.diferencia[key].label, style: 'Rsuccess'});
                    for(const _key in this.periods.step1){
                        const _obj = {text: '$' + this.periods.step1[_key][key], style: ''}
                        row.push(_obj);
                    }
                    rT.push(row);
                }
            }
            let objST = {
                table: {
                    headerRows: 1,
                    widths: [175, 50, 50, '*', '*'],
                    body: rT
                }
            }
            tableComplete.push(objST);
            // Conciliaciones
            const CON = this.config.conciliation;
            let subHeaderCON = { text: CON.basic.label, style: 'header'};
            tableComplete.push(subHeaderCON);
            rT = [];
            for(const key in CON.inv_inventarios){
                if(CON.inv_inventarios[key].title === true){
                    rT.push([
                        {text: CON.inv_inventarios[key].label, style: 'HeadT'},
                        {text: '', style: 'HeadT'},
                    ]);
                }else{
                    let row = [];
                    row.push({text: CON.inv_inventarios[key].label, style: 'Rsuccess'});
                    for(const _key in this.periods.conciliation){
                        const _obj = {text: '$' + this.periods.conciliation[_key].inv_inventarios[key], style: ''}
                        row.push(_obj);
                    }
                    rT.push(row);
                }
            }
            for(const key in CON.revaluacion_subsidarias){
                if(CON.revaluacion_subsidarias[key].title === true){
                    rT.push([
                        {text: CON.revaluacion_subsidarias[key].label, style: 'HeadT'},
                        {text: '', style: 'HeadT'},
                    ]);
                }else{
                    let row = [];
                    row.push({text: CON.revaluacion_subsidarias[key].label, style: 'Rsuccess'});
                    for(const _key in this.periods.conciliation){
                        const _obj = {text: '$' + this.periods.conciliation[_key].revaluacion_subsidarias[key], style: ''}
                        row.push(_obj);
                    }
                    rT.push(row);
                }
            }
            for(const key in CON.inversion_propiedad){
                if(CON.inversion_propiedad[key].title === true){
                    rT.push([
                        {text: CON.inversion_propiedad[key].label, style: 'HeadT'},
                        {text: '', style: 'HeadT'},
                    ]);
                }else{
                    let row = [];
                    row.push({text: CON.inversion_propiedad[key].label, style: 'Rsuccess'});
                    for(const _key in this.periods.conciliation){
                        const _obj = {text: '$' + this.periods.conciliation[_key].inversion_propiedad[key], style: ''}
                        row.push(_obj);
                    }
                    rT.push(row);
                }
            }
            for(const key in CON.actualizacion_capital){
                if(CON.actualizacion_capital[key].title === true){
                    rT.push([
                        {text: CON.actualizacion_capital[key].label, style: 'HeadT'},
                        {text: '', style: 'HeadT'},
                    ]);
                }else{
                    let row = [];
                    row.push({text: CON.actualizacion_capital[key].label, style: 'Rsuccess'});
                    for(const _key in this.periods.conciliation){
                        const _obj = {text: '$' + this.periods.conciliation[_key].actualizacion_capital[key], style: ''}
                        row.push(_obj);
                    }
                    rT.push(row);
                }
            }
            for(const key in CON.actualizacion_resultados){
                if(CON.actualizacion_resultados[key].title === true){
                    rT.push([
                        {text: CON.actualizacion_resultados[key].label, style: 'HeadT'},
                        {text: '', style: 'HeadT'},
                    ]);
                }else{
                    let row = [];
                    row.push({text: CON.actualizacion_resultados[key].label, style: 'Rsuccess'});
                    for(const _key in this.periods.conciliation){
                        const _obj = {text: '$' + this.periods.conciliation[_key].actualizacion_resultados[key], style: ''}
                        row.push(_obj);
                    }
                    rT.push(row);
                }
            }
            for(const key in CON.cargos_no_monetarios){
                if(CON.cargos_no_monetarios[key].title === true){
                    rT.push([
                        {text: CON.cargos_no_monetarios[key].label, style: 'HeadT'},
                        {text: '', style: 'HeadT'},
                    ]);
                }else{
                    let row = [];
                    row.push({text: CON.cargos_no_monetarios[key].label, style: 'Rsuccess'});
                    for(const _key in this.periods.conciliation){
                        const _obj = {text: '$' + this.periods.conciliation[_key].cargos_no_monetarios[key], style: ''}
                        row.push(_obj);
                    }
                    rT.push(row);
                }
            }
            for(const key in CON.isr_ptu){
                if(CON.isr_ptu[key].title === true){
                    rT.push([
                        {text: CON.isr_ptu[key].label, style: 'HeadT'},
                        {text: '', style: 'HeadT'},
                    ]);
                }else{
                    let row = [];
                    row.push({text: CON.isr_ptu[key].label, style: 'Rsuccess'});
                    for(const _key in this.periods.conciliation){
                        const _obj = {text: '$' + this.periods.conciliation[_key].isr_ptu[key], style: ''}
                        row.push(_obj);
                    }
                    rT.push(row);
                }
            }
            for(const key in CON.perdida_utilidad_cambiaria){
                if(CON.perdida_utilidad_cambiaria[key].title === true){
                    rT.push([
                        {text: CON.perdida_utilidad_cambiaria[key].label, style: 'HeadT'},
                        {text: '', style: 'HeadT'},
                    ]);
                }else{
                    let row = [];
                    row.push({text: CON.perdida_utilidad_cambiaria[key].label, style: 'Rsuccess'});
                    for(const _key in this.periods.conciliation){
                        const _obj = {text: '$' + this.periods.conciliation[_key].perdida_utilidad_cambiaria[key], style: ''}
                        row.push(_obj);
                    }
                    rT.push(row);
                }
            }
            let objCON = {
                table: {
                    headerRows: 0,
                    widths: [250, '*'],
                    body: rT
                }
            }
            tableComplete.push(objCON)
            // Estado de flujo completo
            const CF = this.config.cash_flow;
            let subHeaderCF = { text: 'ESTADO DE FLUJO DE EFECTIVO', style: 'header'};
            tableComplete.push(subHeaderCF);
            rT = [];
            for(const key in CF){
                if(CF[key].title === true && key == 'cells'){
                    rT.push([
                        {text: CF[key].label, style: 'HeadT'},
                        {text: '2022', style: 'HeadT'},
                    ]);
                }else if(!['basic', 'cells'].includes(key)){
                    let row = [];
                    row.push({text: CF[key].label, style: 'Rsuccess'});
                    for(const _key in this.periods.cash_flow){
                        const _obj = {text: '$' + this.periods.cash_flow[_key][key], style: ''}
                        row.push(_obj);
                    }
                    rT.push(row);
                }

            }
            let objCF = {
                table: {
                    headerRows: 1,
                    widths: [300, '*'],
                    body: rT
                }
            }
            tableComplete.push(objCF);
            let subHeader = { text: 'JUSTIFICACION DE SU RESPUESTA', style: 'header'}
            tableComplete.push(subHeader);
            let awnser = {text: this.periods.qualitative.basic, style: 'header'}
            tableComplete.push(awnser);
            //console.log(tableComplete);
            Event.$emit("event-complete-practice", tableComplete);
        }

    }
}
</script>

<template>
    <div>
        <div>
            <div class="flex analysis-nav">
                <button class="button analysis-nav-item" :class="{'button-active': menu.active, 'button-inactive': !menu.active}" v-for="(menu, index) in menuNav" :key="`menunav-${index}`" @click="setActive(menu)">{{ menu.label }}</button>
            </div>
        </div>
        <!-- Result Status -->
        <div class="box-center box-color">
            <table class="table-registers" >
                <template  v-if="slugSelected == 'instructions'">
                    <tr>
                        <td colspan="5" align="left" class="space-td bg-title-instructions">
                            <img src="@/assets/BBVA_Logo-01.png"
                    style="width: 100px; padding-left: 20px; padding-top: 0px;" alt="">
                        </td>
                    </tr>
                    <tr>
                        <td colspan="5" class="space-td">
                            <b>INSTRUCCIONES:</b>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="5" align="left" class="space-td">
                            Se presenta el Estado de Resultados de la Empresa {{ config.basic.nameCompany }}, así como el Balance General de los periodos 2021 y 2022.
                            <br><b>Deberás construir en 3 Pasos el Flujo de Efectivo: </b>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="5" align="left" class="space-td">
                            <ol>
                                <li>Calcular variaciones de 2022 contra 2021.</li>
                                <li>Determinar si la variación obtenida representa una entrada o salida de dinero.</li>
                                <li>Realizar las Conciliaciones Pertinentes para poder cuadrar dicho Flujo.</li>
                            </ol>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="5" align="left" class="space-td">
                            <b>Una vez construido deberás llegar a los siguientes resultados: </b>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="5" align="left" class="space-td">
                            <ul>
                                <li>El Aumento o disminución de Caja es de -$21. </li>
                                <li>La variación de caja del Balance con relación a la Variación de Caja del Flujo deberá se "0".</li>
                                <li>Y al concluir deberás determinar si el Flujo es suficiente para un Crédito de $100 a largo plazo.</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="5" align="left" class="space-td">
                            <b>Nota: Si lo consideras conveniente, podrás apoyarte en el análisis de razones financieras y deberás explicar porqué.</b>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="5" align="right" class="space-td">
                            <button class="btn btn-lg btn-block btn-bbva text-white" @click="setActive(menuNav[1])"> Continuar
                            </button>
                        </td>
                    </tr>
                </template>
                <tr v-if="slugSelected != 'instructions'">
                    <td colspan="5" class="title-company"><h4>{{ config.basic.nameCompany}}</h4></td>
                </tr>
                <template v-if="slugSelected == 'result_status'">
                    <tr v-for="(row, key) in config.results_status" :key="key" >
                        <td :colspan="row.hasOwnProperty('colspan') ? row.colspan : ''" class="text-left border-bottom" :class="{'bg-primary': row.title}">{{ row.label }}</td>
                        <td v-for="(period, _key) in periods.resultStatus" class="pl-2 border-bottom" :key="_key" :class="{'bg-total': ['sum', 'sum_sub'].includes(row.type)}">
                            <input type="number" :disabled="row.disabled" v-model="periods.resultStatus[_key][key]" class="form-control form-control-sm" v-if="row.type === 'number'"/>
                            <input type="text" :disabled="row.disabled" :value="sumCells(periods.resultStatus[_key], row.items, key,)" class="form-control form-control-sm" v-if="row.type === 'sum'"/>
                            <input type="text" :disabled="row.disabled" :value="subCells(periods.resultStatus[_key], row.sum, row.sub, key)" class="form-control form-control-sm" v-if="row.type === 'sum_sub'"/>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="5" align="right" class="space-td">
                            <button class="btn btn-lg btn-block btn-bbva text-white" @click="setActive(menuNav[2])"> Continuar
                            </button>
                        </td>
                    </tr>
                </template>
                <!-- Paso 1 y paso 2 -->
                <template v-if="slugSelected == 'step1' || slugSelected == 'step2'">
                    <tr v-if="slugSelected == 'step1'" :class="config.step1.basic.hasOwnProperty('bg') ? config.step1.basic.bg : ''">
                        <td>{{ config.step1.basic.label }}</td>
                        <td v-for="(per, index) in config.step1.basic.td" :key="index">{{ per }}</td>
                    </tr>
                    <tr v-if="slugSelected == 'step2'" :class="config.step2.basic.hasOwnProperty('bg') ? config.step2.basic.bg : ''">
                        <td>{{ config.step2.basic.label }}</td>
                        <td v-for="(per, index) in config.step2.basic.td" :key="index">{{ per }}</td>
                    </tr>
                    <!-- Activos circulantes -->
                    <tr v-for="(row, key) in config.step1.activos_circulantes" :key="key" >
                        <td :colspan="row.hasOwnProperty('colspan') ? row.colspan : ''" class="text-left border-bottom" :class="row.hasOwnProperty('bg') ? row.bg : ''">{{row.label}} {{  }}</td>
                        <td v-for="(period, _key) in periods.step1" class="pl-2 border-bottom" :key="_key" :class="{'bg-total': ['sum', 'sum_sub'].includes(row.type)}">
                            <div v-if="!['diff', 'entrance_exit'].includes(_key) && slugSelected == 'step1'">
                                <input type="number" :disabled="row.disabled" v-model="periods.step1[_key].activos_circulantes[key]" class="form-control form-control-sm" v-if="row.type === 'number'"/>
                                <input type="text" :disabled="row.disabled" :value="sumCells(periods.step1[_key].activos_circulantes, row.items, key,)" class="form-control form-control-sm" v-if="row.type === 'sum'"/>
                                <input type="text" :disabled="row.disabled" :value="subCells(periods.step1[_key].activos_circulantes, row.sum, row.sub, key)" class="form-control form-control-sm" v-if="row.type === 'sum_sub'"/>
                            </div>
                            <div v-if="['diff'].includes(_key) && (slugSelected == 'step1' || slugSelected == 'step2')">
                                <span style="display: block; width: 100% !important" v-if="!row.title && row.type !== 'sum'">
                                    <input :type="row.type" :disabled="false" v-model="periods.step1[_key].activos_circulantes[key]" class="form-control form-control-sm"/>
                                    <!-- {{ subRow(periods.step1, 'activos_circulantes', key) }} -->
                                </span>
                            </div>
                            <div v-if="['entrance_exit'].includes(_key) && slugSelected == 'step2'">
                                <span style="display: block; width: 100% !important" v-if="!row.title && row.type !== 'sum'">
                                    <input
                                    :type="row.type"
                                    :class="{
                                        'input-success': periods.step1[_key].activos_circulantes[key] > 0,
                                        'input-danger': periods.step1[_key].activos_circulantes[key] < 0,
                                    }"
                                    :disabled="false"
                                    v-model="periods.step1[_key].activos_circulantes[key]"
                                    class="form-control form-control-sm"
                                    />
                                    <!-- {{ subRow(periods.step1, 'activos_circulantes', key) }} -->
                                </span>
                            </div>
                        </td>
                    </tr>
                    <!-- Activos Fijos -->
                    <tr v-for="(row, key) in config.step1.activo_fijo" :key="key" >
                        <td :colspan="row.hasOwnProperty('colspan') ? row.colspan : ''" class="text-left border-bottom" :class="row.hasOwnProperty('bg') ? row.bg : ''">{{row.label}} {{  }}</td>
                        <td v-for="(period, _key) in periods.step1" class="pl-2 border-bottom" :key="_key" :class="{'bg-total': ['sum', 'sum_sub'].includes(row.type)}">
                            <div  v-if="!['diff', 'entrance_exit'].includes(_key) && slugSelected == 'step1'">
                                <input type="number" :disabled="row.disabled" v-model="periods.step1[_key].activo_fijo[key]" class="form-control form-control-sm" v-if="row.type === 'number'"/>
                                <input type="text" :disabled="row.disabled" :value="sumCells(periods.step1[_key].activo_fijo, row.items, key,)" class="form-control form-control-sm" v-if="row.type === 'sum'"/>
                                <input type="text" :disabled="row.disabled" :value="subCells(periods.step1[_key].activo_fijo, row.sum, row.sub, key)" class="form-control form-control-sm" v-if="row.type === 'sum_sub'"/>
                            </div>
                            <div v-if="['diff'].includes(_key) && (slugSelected == 'step1' || slugSelected == 'step2')">
                                <span v-if="!row.title && row.type !== 'sum'">
                                    <input :type="row.type" :disabled="false" v-model="periods.step1[_key].activo_fijo[key]" class="form-control form-control-sm"/>
                                    <!-- {{ subRow(periods.step1, 'activo_fijo', key) }} -->
                                </span>
                            </div>
                            <div v-if="['entrance_exit'].includes(_key) && slugSelected == 'step2'">
                                <span v-if="!row.title && row.type !== 'sum'">
                                    <input :type="row.type"
                                    :class="{
                                        'input-success': periods.step1[_key].activo_fijo[key] > 0,
                                        'input-danger': periods.step1[_key].activo_fijo[key] < 0,
                                    }"
                                    :disabled="false" v-model="periods.step1[_key].activo_fijo[key]" class="form-control form-control-sm"/>
                                    <!-- {{ subRow(periods.step1, 'activo_fijo', key) }} -->
                                </span>
                            </div>
                        </td>
                    </tr>
                    <!-- otros Activos -->
                    <tr v-for="(row, key) in config.step1.otros_activos" :key="key" >
                        <td :colspan="row.hasOwnProperty('colspan') ? row.colspan : ''" class="text-left border-bottom" :class="row.hasOwnProperty('bg') ? row.bg : ''">{{row.label}} {{  }}</td>
                        <td v-for="(period, _key) in periods.step1" class="pl-2 border-bottom" :key="_key" :class="{'bg-total': ['sum', 'sum_sub'].includes(row.type)}">
                            <div v-if="!['diff', 'entrance_exit'].includes(_key) && slugSelected == 'step1'">
                                <input type="number" :disabled="row.disabled" v-model="periods.step1[_key].otros_activos[key]" class="form-control form-control-sm" v-if="row.type === 'number'"/>
                                <input type="text" :disabled="row.disabled" :value="sumCells(periods.step1[_key].otros_activos, row.items, key,)" class="form-control form-control-sm" v-if="row.type === 'sum'"/>
                                <input type="text" :disabled="row.disabled" :value="subCells(periods.step1[_key].otros_activos, row.sum, row.sub, key)" class="form-control form-control-sm" v-if="row.type === 'sum_sub'"/>
                            </div>
                            <div v-if="['diff'].includes(_key) && (slugSelected == 'step1' || slugSelected == 'step2')">
                                <span v-if="!row.title && row.type !== 'sum'">
                                    <input :type="row.type" :disabled="false" v-model="periods.step1[_key].otros_activos[key]" class="form-control form-control-sm"/>
                                    <!-- {{ subRow(periods.step1, 'otros_activos', key) }} -->
                                </span>
                            </div>
                            <div v-if="['entrance_exit'].includes(_key) && slugSelected == 'step2'">
                                <span v-if="!row.title && row.type !== 'sum'">
                                    <input :type="row.type"
                                    :class="{
                                        'input-success': periods.step1[_key].otros_activos[key] > 0,
                                        'input-danger': periods.step1[_key].otros_activos[key] < 0,
                                    }"
                                    :disabled="false" v-model="periods.step1[_key].otros_activos[key]" class="form-control form-control-sm"/>
                                    <!-- {{ subRow(periods.step1, 'otros_activos', key) }} -->
                                </span>
                            </div>
                        </td>
                    </tr>
                    <!-- TOTAL Activos -->
                    <tr v-for="(row, key) in config.step1.total_activos" :key="key" >
                        <td :colspan="row.hasOwnProperty('colspan') ? row.colspan : ''" class="text-left border-bottom" :class="row.hasOwnProperty('bg') ? row.bg : ''">{{row.label}}</td>
                        <td v-for="(period, _key) in periods.step1" class="pl-2 border-bottom" :key="_key" :class="{'bg-title text-white': ['sum', 'sum_sub'].includes(row.type)}">
                            <div v-if="!['diff', 'entrance_exit'].includes(_key) && slugSelected == 'step1'">
                                <!-- <input type="number" :disabled="row.disabled" v-model="periods.step1[_key].otros_activos" class="form-control form-control-sm" v-if="row.type === 'number'"/> -->
                                <input type="string" :disabled="row.disabled" :value="sumCellsTotal(periods.step1[_key], row.items, key, config.step1)" class="form-control form-control-sm text-white" v-if="row.type === 'sum'"/>
                                <!-- <input type="string" :disabled="row.disabled" :value="subCells(periods.step1[_key].otros_activos, row.sum, row.sub, key)" class="form-control form-control-sm" v-if="row.type === 'sum_sub'"/> -->
                            </div>
                        </td>
                    </tr>
                    <!-- Pasivo corto plazo -->
                    <tr v-for="(row, key) in config.step1.pasivo_corto_plazo" :key="key" >
                        <td :colspan="row.hasOwnProperty('colspan') ? row.colspan : ''" class="text-left border-bottom" :class="row.hasOwnProperty('bg') ? row.bg : ''">{{row.label}} {{  }}</td>
                        <td v-for="(period, _key) in periods.step1" class="pl-2 border-bottom" :key="_key" :class="{'bg-total': ['sum', 'sum_sub'].includes(row.type)}">
                            <div v-if="!['diff', 'entrance_exit'].includes(_key) && slugSelected == 'step1'">
                                <input type="number" :disabled="row.disabled" v-model="periods.step1[_key].pasivo_corto_plazo[key]" class="form-control form-control-sm" v-if="row.type === 'number'"/>
                                <input type="text" :disabled="row.disabled" :value="sumCells(periods.step1[_key].pasivo_corto_plazo, row.items, key,)" class="form-control form-control-sm" v-if="row.type === 'sum'"/>
                                <input type="text" :disabled="row.disabled" :value="subCells(periods.step1[_key].pasivo_corto_plazo, row.sum, row.sub, key)" class="form-control form-control-sm" v-if="row.type === 'sum_sub'"/>
                            </div>
                            <div v-if="['diff'].includes(_key) && (slugSelected == 'step1' || slugSelected == 'step2')">
                                <span style="display: block; width: 100% !important" v-if="!row.title && row.type !== 'sum'">
                                    <input :type="row.type" :disabled="false" v-model="periods.step1[_key].pasivo_corto_plazo[key]" class="form-control form-control-sm"/>
                                    <!-- {{ subRow(periods.step1, 'activos_circulantes', key) }} -->
                                </span>
                            </div>
                            <div v-if="['entrance_exit'].includes(_key) && slugSelected == 'step2'">
                                <span style="display: block; width: 100% !important" v-if="!row.title && row.type !== 'sum'">
                                    <input :type="row.type"
                                    :class="{
                                        'input-success': periods.step1[_key].pasivo_corto_plazo[key] > 0,
                                        'input-danger': periods.step1[_key].pasivo_corto_plazo[key] < 0,
                                    }"
                                    :disabled="false" v-model="periods.step1[_key].pasivo_corto_plazo[key]" class="form-control form-control-sm"/>
                                    <!-- {{ subRow(periods.step1, 'activos_circulantes', key) }} -->
                                </span>
                            </div>
                        </td>
                    </tr>
                    <!-- Pasivo LARGO plazo -->
                    <tr v-for="(row, key) in config.step1.pasivo_largo_plazo" :key="key" >
                        <td :colspan="row.hasOwnProperty('colspan') ? row.colspan : ''" class="text-left border-bottom" :class="row.hasOwnProperty('bg') ? row.bg : ''">{{row.label}} {{  }}</td>
                        <td v-for="(period, _key) in periods.step1" class="pl-2 border-bottom" :key="_key" :class="{'bg-total': ['sum', 'sum_sub'].includes(row.type)}">
                            <div v-if="!['diff', 'entrance_exit'].includes(_key) && slugSelected == 'step1'">
                                <input type="number" :disabled="row.disabled" v-model="periods.step1[_key].pasivo_largo_plazo[key]" class="form-control form-control-sm" v-if="row.type === 'number'"/>
                                <input type="string" :disabled="row.disabled" :value="sumCells(periods.step1[_key].pasivo_largo_plazo, row.items, key,)" class="form-control form-control-sm" v-if="row.type === 'sum'"/>
                                <input type="string" :disabled="row.disabled" :value="subCells(periods.step1[_key].pasivo_largo_plazo, row.sum, row.sub, key)" class="form-control form-control-sm" v-if="row.type === 'sum_sub'"/>
                            </div>
                            <div v-if="['diff'].includes(_key) && (slugSelected == 'step1' || slugSelected == 'step2')">
                                <span style="display: block; width: 100% !important" v-if="!row.title && row.type !== 'sum'">
                                    <input :type="row.type" :disabled="false" v-model="periods.step1[_key].pasivo_largo_plazo[key]" class="form-control form-control-sm"/>
                                    <!-- {{ subRow(periods.step1, 'activos_circulantes', key) }} -->
                                </span>
                            </div>
                            <div v-if="['entrance_exit'].includes(_key) && slugSelected == 'step2'">
                                <span style="display: block; width: 100% !important" v-if="!row.title && row.type !== 'sum'">
                                    <input :type="row.type"
                                    :class="{
                                        'input-success': periods.step1[_key].pasivo_largo_plazo[key] > 0,
                                        'input-danger': periods.step1[_key].pasivo_largo_plazo[key] < 0,
                                    }"
                                    :disabled="false" v-model="periods.step1[_key].pasivo_largo_plazo[key]" class="form-control form-control-sm"/>
                                    <!-- {{ subRow(periods.step1, 'activos_circulantes', key) }} -->
                                </span>
                            </div>
                        </td>
                    </tr>
                    <!-- TOTAL Pasivos -->
                    <tr v-for="(row, key) in config.step1.total_pasivos" :key="key" >
                        <td :colspan="row.hasOwnProperty('colspan') ? row.colspan : ''" class="text-left border-bottom" :class="row.hasOwnProperty('bg') ? row.bg : ''">{{row.label}} {{  }}</td>
                        <td v-for="(period, _key) in periods.step1" class="pl-2 border-bottom" :key="_key" :class="{'bg-title text-white': ['sum', 'sum_sub'].includes(row.type)}">
                            <div v-if="!['diff', 'entrance_exit'].includes(_key) && slugSelected == 'step1'">
                                <!-- <input type="number" :disabled="row.disabled" v-model="periods.step1[_key].otros_activos" class="form-control form-control-sm" v-if="row.type === 'number'"/> -->
                                <input type="string" :disabled="row.disabled" :value="sumCellsTotal(periods.step1[_key], row.items, key, config.step1)" class="form-control form-control-sm text-white" v-if="row.type === 'sum'"/>
                                <!-- <input type="string" :disabled="row.disabled" :value="subCells(periods.step1[_key].otros_activos, row.sum, row.sub, key)" class="form-control form-control-sm" v-if="row.type === 'sum_sub'"/> -->
                            </div>
                        </td>
                    </tr>
                    <!-- Capital -->
                    <tr v-for="(row, key) in config.step1.capital" :key="key" >
                        <td :colspan="row.hasOwnProperty('colspan') ? row.colspan : ''" class="text-left border-bottom" :class="row.hasOwnProperty('bg') ? row.bg : ''">{{row.label}} {{  }}</td>
                        <td v-for="(period, _key) in periods.step1" class="pl-2 border-bottom" :key="_key" :class="{'bg-total': ['sum', 'sum_sub'].includes(row.type)}">
                            <div v-if="!['diff', 'entrance_exit'].includes(_key) && slugSelected == 'step1'">
                                <input type="number" :disabled="row.disabled" v-model="periods.step1[_key].capital[key]" class="form-control form-control-sm" v-if="row.type === 'number'"/>
                                <input type="text" :disabled="row.disabled" :value="sumCells(periods.step1[_key].capital, row.items, key,)" class="form-control form-control-sm" v-if="row.type === 'sum'"/>
                                <input type="text" :disabled="row.disabled" :value="subCells(periods.step1[_key].capital, row.sum, row.sub, key)" class="form-control form-control-sm" v-if="row.type === 'sum_sub'"/>
                                <input type="number" :disabled="row.disabled" :value="importCells(periods.step1[_key].capital, periods[row.import.section][_key][row.import.key], key)" class="form-control form-control-sm" v-if="row.type === 'import'"/>
                            </div>
                            <div v-if="['diff'].includes(_key) && (slugSelected == 'step1' || slugSelected == 'step2')">
                                <span style="display: block; width: 100% !important" v-if="!row.title && row.type !== 'sum'">
                                    <input :type="row.type == 'import' ? 'number' : row.type" :disabled="false" v-model="periods.step1[_key].capital[key]" class="form-control form-control-sm"/>
                                    <!-- {{ subRow(periods.step1, 'activos_circulantes', key) }} -->
                                </span>
                            </div>
                            <div v-if="['entrance_exit'].includes(_key) && slugSelected == 'step2'">
                                <span style="display: block; width: 100% !important" v-if="!row.title && row.type !== 'sum'">
                                    <input :type="row.type == 'import' ? 'number' : row.type"
                                    :class="{
                                        'input-success': periods.step1[_key].capital[key] > 0,
                                        'input-danger': periods.step1[_key].capital[key] < 0,
                                    }"
                                    :disabled="false" v-model="periods.step1[_key].capital[key]" class="form-control form-control-sm"/>
                                    <!-- {{ subRow(periods.step1, 'activos_circulantes', key) }} -->
                                </span>
                            </div>
                        </td>
                    </tr>
                    <!-- suma capital pasivos -->
                    <tr v-for="(row, key) in config.step1.suma_pasivo_capital" :key="key" >
                        <td :colspan="row.hasOwnProperty('colspan') ? row.colspan : ''" class="text-left border-bottom" :class="row.hasOwnProperty('bg') ? row.bg : ''">{{row.label}} {{  }}</td>
                        <td v-for="(period, _key) in periods.step1" class="pl-2 border-bottom" :key="_key" :class="{'bg-title text-white': ['sum', 'sum_sub'].includes(row.type)}">
                            <div v-if="!['diff', 'entrance_exit'].includes(_key) && slugSelected == 'step1'">
                                <!-- <input type="number" :disabled="row.disabled" v-model="periods.step1[_key].otros_activos" class="form-control form-control-sm" v-if="row.type === 'number'"/> -->
                                <input type="text" :disabled="row.disabled" :value="sumCellsTotal(periods.step1[_key], row.items, key, config.step1)" class="form-control form-control-sm text-white" v-if="row.type === 'sum'"/>
                                <!-- <input type="string" :disabled="row.disabled" :value="subCells(periods.step1[_key].otros_activos, row.sum, row.sub, key)" class="form-control form-control-sm" v-if="row.type === 'sum_sub'"/> -->
                            </div>
                        </td>
                    </tr>
                    <!-- suma capital pasivos -->
                    <tr v-for="(row, key) in config.step1.diferencia" :key="key" >
                        <td :colspan="row.hasOwnProperty('colspan') ? row.colspan : ''" class="text-left border-bottom" :class="row.hasOwnProperty('bg') ? row.bg : ''">{{row.label}} {{  }}</td>
                        <td v-for="(period, _key) in periods.step1" class="pl-2 border-bottom" :key="_key" :class="{'bg-title text-white': ['sum', 'sum_sub'].includes(row.type)}">
                            <div v-if="!['diff', 'entrance_exit'].includes(_key) && slugSelected == 'step1'">
                                <!-- <input type="number" :disabled="row.disabled" v-model="periods.step1[_key].otros_activos" class="form-control form-control-sm" v-if="row.type === 'number'"/> -->
                                <input type="string" :disabled="row.disabled" :value="subCellsTotal(periods.step1[_key], row.sum, row.sub, key, config.step1)" class="form-control form-control-sm text-white" v-if="row.type === 'sum_sub'"/>
                                <!-- <input type="string" :disabled="row.disabled" :value="subCells(periods.step1[_key].otros_activos, row.sum, row.sub, key)" class="form-control form-control-sm" v-if="row.type === 'sum_sub'"/> -->
                            </div>
                        </td>
                    </tr>
                    <tr v-if="slugSelected == 'step1'">
                        <td colspan="5" align="right" class="space-td">
                            <button class="btn btn-lg btn-block btn-bbva text-white" @click="setActive(menuNav[3])"> Continuar
                            </button>
                        </td>
                    </tr>
                    <tr v-if="slugSelected == 'step2'">
                        <td colspan="5" align="right" class="space-td">
                            <button class="btn btn-lg btn-block btn-bbva text-white" @click="setActive(menuNav[4])"> Continuar
                            </button>
                        </td>
                    </tr>
                </template>
                <!-- Paso 2 -->
                <!-- <template v-if="slugSelected == 'step2'">
                    <h1>Pas 2</h1>
                </template> -->
                <!-- Conciliaciones -->
                <template v-if="slugSelected == 'conciliation'">
                    <tr :class="config.conciliation.basic.hasOwnProperty('bg') ? config.conciliation.basic.bg : ''">
                        <td :colspan="config.conciliation.basic.hasOwnProperty('colspan') ? config.conciliation.basic.colspan : ''" >{{ config.conciliation.basic.label }}</td>
                    </tr>
                    
                    <!-- inversiones inventarios-->
                    <tr v-for="(row, key) in config.conciliation.inv_inventarios" :key="key" >
                        <td :colspan="row.hasOwnProperty('colspan') ? row.colspan : ''" class="text-left border-bottom" :class="row.hasOwnProperty('bg') ? row.bg : ''"><span v-if="row.hasOwnProperty('simbol') && row.simbol !== ''">({{ row.simbol }})</span> {{row.label}}</td>
                        <td v-for="(period, _key) in periods.conciliation" class="pl-2 border-bottom" :key="_key">
                            <div>
                                <input
                                type="number"
                                :class="{
                                    'input-conci': row.hasOwnProperty('bg'),
                                }"
                                :disabled="row.disabled"
                                v-model="periods.conciliation[_key].inv_inventarios[key]"
                                class="form-control form-control-sm"
                                v-if="row.type === 'number'"
                                />
                            </div>
                        </td>
                    </tr>
                    
                    <!-- Revaluación en Subsidiarias y Afiliadas-->
                    <tr v-for="(row, key) in config.conciliation.revaluacion_subsidarias" :key="key" >
                        <td :colspan="row.hasOwnProperty('colspan') ? row.colspan : ''" class="text-left border-bottom" :class="row.hasOwnProperty('bg') ? row.bg : ''"><span v-if="row.hasOwnProperty('simbol') && row.simbol !== ''">({{ row.simbol }})</span> {{row.label}}</td>
                        <td v-for="(period, _key) in periods.conciliation" class="pl-2 border-bottom" :key="_key">
                            <div>
                                <input
                                type="number"
                                :class="{
                                    'input-conci': row.hasOwnProperty('bg'),
                                }"
                                :disabled="row.disabled"
                                v-model="periods.conciliation[_key].revaluacion_subsidarias[key]"
                                class="form-control form-control-sm"
                                v-if="row.type === 'number'"
                                />
                            </div>
                        </td>
                    </tr>
                    <!-- Inversión en Propiedad Planta y Equipo -->
                    <tr v-for="(row, key) in config.conciliation.inversion_propiedad" :key="key" >
                        <td :colspan="row.hasOwnProperty('colspan') ? row.colspan : ''" class="text-left border-bottom" :class="row.hasOwnProperty('bg') ? row.bg : ''"><span v-if="row.hasOwnProperty('simbol') && row.simbol !== ''">({{ row.simbol }})</span> {{row.label}}</td>
                        <td v-for="(period, _key) in periods.conciliation" class="pl-2 border-bottom" :key="_key">
                            <div>
                                <input
                                type="number"
                                :class="{
                                    'input-conci': row.hasOwnProperty('bg'),
                                }"
                                :disabled="row.disabled"
                                v-model="periods.conciliation[_key].inversion_propiedad[key]"
                                class="form-control form-control-sm"
                                v-if="row.type === 'number'"
                                />
                            </div>
                        </td>
                    </tr>
                    <!-- Actualización de Capital -->
                    <tr v-for="(row, key) in config.conciliation.actualizacion_capital" :key="key" >
                        <td :colspan="row.hasOwnProperty('colspan') ? row.colspan : ''" class="text-left border-bottom" :class="row.hasOwnProperty('bg') ? row.bg : ''"><span v-if="row.hasOwnProperty('simbol') && row.simbol !== ''">({{ row.simbol }})</span> {{row.label}}</td>
                        <td v-for="(period, _key) in periods.conciliation" class="pl-2 border-bottom" :key="_key">
                            <div>
                                <input
                                type="number"
                                :class="{
                                    'input-conci': row.hasOwnProperty('bg'),
                                }"
                                :disabled="row.disabled"
                                v-model="periods.conciliation[_key].actualizacion_capital[key]"
                                class="form-control form-control-sm"
                                v-if="row.type === 'number'"
                                />
                            </div>
                        </td>
                    </tr>
                    <!-- Actualización de Resultados -->
                    <tr v-for="(row, key) in config.conciliation.actualizacion_resultados" :key="key" >
                        <td :colspan="row.hasOwnProperty('colspan') ? row.colspan : ''" class="text-left border-bottom" :class="row.hasOwnProperty('bg') ? row.bg : ''"><span v-if="row.hasOwnProperty('simbol') && row.simbol !== ''">({{ row.simbol }})</span> {{row.label}}</td>
                        <td v-for="(period, _key) in periods.conciliation" class="pl-2 border-bottom" :key="_key">
                            <div>
                                <input
                                type="number"
                                :class="{
                                    'input-conci': row.hasOwnProperty('bg'),
                                }"
                                :disabled="row.disabled"
                                v-model="periods.conciliation[_key].actualizacion_resultados[key]"
                                class="form-control form-control-sm"
                                v-if="row.type === 'number'"
                                />
                            </div>
                        </td>
                    </tr>
                    <!-- Cargos no monetarios -->
                    <tr v-for="(row, key) in config.conciliation.cargos_no_monetarios" :key="key" >
                        <td :colspan="row.hasOwnProperty('colspan') ? row.colspan : ''" class="text-left border-bottom" :class="row.hasOwnProperty('bg') ? row.bg : ''"><span v-if="row.hasOwnProperty('simbol') && row.simbol !== ''">({{ row.simbol }})</span> {{row.label}}</td>
                        <td v-for="(period, _key) in periods.conciliation" class="pl-2 border-bottom" :key="_key">
                            <div>
                                <input
                                type="number"
                                :class="{
                                    'input-conci': row.hasOwnProperty('bg'),
                                }"
                                :disabled="row.disabled"
                                v-model="periods.conciliation[_key].cargos_no_monetarios[key]"
                                class="form-control form-control-sm"
                                v-if="row.type === 'number'"
                                />
                            </div>
                        </td>
                    </tr>
                    <!-- ISR y PTU -->
                    <tr v-for="(row, key) in config.conciliation.isr_ptu" :key="key" >
                        <td :colspan="row.hasOwnProperty('colspan') ? row.colspan : ''" class="text-left border-bottom" :class="row.hasOwnProperty('bg') ? row.bg : ''"><span v-if="row.hasOwnProperty('simbol') && row.simbol !== ''">({{ row.simbol }})</span> {{row.label}}</td>
                        <td v-for="(period, _key) in periods.conciliation" class="pl-2 border-bottom" :key="_key">
                            <div>
                                <input
                                type="number"
                                :class="{
                                    'input-conci': row.hasOwnProperty('bg'),
                                }"
                                :disabled="row.disabled"
                                v-model="periods.conciliation[_key].isr_ptu[key]"
                                class="form-control form-control-sm"
                                v-if="row.type === 'number'"
                                />
                                <input type="number" :class="{
                                    'input-conci': row.hasOwnProperty('bg'),
                                }"
                                :disabled="row.disabled" :value="sumCells(periods.conciliation[_key].isr_ptu, row.items, key, row.negative)" class="form-control form-control-sm" v-if="row.type === 'sum'"/>
                            </div>
                        </td>
                    </tr>
                    <!-- Perdida o Utilidad Cambiaria  -->
                    <tr v-for="(row, key) in config.conciliation.perdida_utilidad_cambiaria" :key="key" >
                        <td :colspan="row.hasOwnProperty('colspan') ? row.colspan : ''" class="text-left border-bottom" :class="row.hasOwnProperty('bg') ? row.bg : ''"><span v-if="row.hasOwnProperty('simbol') && row.simbol !== ''">({{ row.simbol }})</span> {{row.label}}</td>
                        <td v-for="(period, _key) in periods.conciliation" class="pl-2 border-bottom" :key="_key">
                            <div>
                                <input
                                type="number"
                                :class="{
                                    'input-conci': row.hasOwnProperty('bg'),
                                }"
                                :disabled="row.disabled"
                                v-model="periods.conciliation[_key].perdida_utilidad_cambiaria[key]"
                                class="form-control form-control-sm"
                                v-if="row.type === 'number'"
                                />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="5" align="right" class="space-td">
                            <button class="btn btn-lg btn-block btn-bbva text-white" @click="setActive(menuNav[5])"> Continuar
                            </button>
                        </td>
                    </tr>
                </template>
                <!-- Flujo de Efectivo Final -->
                <template v-if="slugSelected == 'ending_cash_flow'">
                    <tr :class="config.cash_flow.basic.hasOwnProperty('bg') ? config.cash_flow.basic.bg : ''">
                        <td :colspan="config.cash_flow.basic.hasOwnProperty('colspan') ? config.cash_flow.basic.colspan : ''" >{{ config.cash_flow.basic.label }}</td>
                    </tr>
                    <tr :class="config.cash_flow.cells.hasOwnProperty('bg') ? config.cash_flow.cells.bg : ''">
                        <td>{{ config.cash_flow.cells.label }}</td>
                        <td v-for="(per, index) in config.cash_flow.cells.td" :key="index">{{ per }}</td>
                    </tr>
                    <tr v-for="(row, key) in config.cash_flow" :key="key" :colspan="row.hasOwnProperty('colspan') ? row.colspan : ''" class="text-left border-bottom" :class="row.hasOwnProperty('bg') ? row.bg : ''">
                        <td v-if="!['basic', 'cells'].includes(key)" :colspan="row.hasOwnProperty('colspan') ? row.colspan : ''" class="text-left border-bottom" :class="{'bg-primary': row.title}">{{ row.label }}</td>
                        <td v-for="(period, _key) in periods.cash_flow" class="pl-2 border-bottom" :key="_key" :class="{'bg-total': ['sum', 'sum_sub'].includes(row.type)}">
                            <div  v-if="!['basic', 'cells'].includes(key)">
                                <input type="number" :class="{
                                    'input-flow': row.hasOwnProperty('bg'),
                                    'input-success': periods.cash_flow[_key][key] > 0,
                                    'input-danger': periods.cash_flow[_key][key] <= 0,
                                }"
                                :disabled="row.disabled" v-model="periods.cash_flow[_key][key]" class="form-control form-control-sm" v-if="row.type === 'number'"/>
                                <input type="number" :class="{
                                    'input-flow': row.hasOwnProperty('bg'),
                                    'input-success': periods.cash_flow[_key][key] > 0,
                                    'input-danger': periods.cash_flow[_key][key] <= 0,
                                }"
                                :disabled="row.disabled" :value="sumCells(periods.cash_flow[_key], row.items, key, row.negative)" class="form-control form-control-sm" v-if="row.type === 'sum'"/>
                                <input type="number" :class="{
                                    'input-flow': row.hasOwnProperty('bg'),
                                    'input-success': periods.cash_flow[_key][key] > 0,
                                    'input-danger': periods.cash_flow[_key][key] <= 0,
                                }"
                                :disabled="row.disabled" :value="subCells(periods.cash_flow[_key], row.sum, row.sub, key)" class="form-control form-control-sm" v-if="row.type === 'sum_sub'"/>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="pt-4">
                            <label><b>{{ config.qualitative.basic.label }} </b> <span class="text-danger">*</span></label>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="pb-2">
                            <textarea class="form-control" v-model="periods.qualitative.basic"></textarea>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="4">
                            <div class="d-grid">
                                <button class="mt-2 btn btn-lg btn-block btn-bbva text-white w-100" @click="finishExamen()">Finalizar</button>
                            </div>
                        </td>
                    </tr>
                </template>
            </table>
        </div>
    </div>
</template>

<style lang="scss" scoped>
h1 {
    color: white;
}
/* .flex{
    display: flex;
} */
.analysis-nav{
    overflow: hidden;
}
.analysis-nav-item{
    border-radius: 0;
    border-bottom: transparent;
    white-space: nowrap;
}
.analysis-nav-item-active{
    background-color: #c9c9c9;
}

.input-success{
    color: darkgreen;
    background-color: rgba(green, 0.2) !important;
}

.input-danger{
    color: darkred;
    background-color: rgba(red, 0.3) !important;
}

.input-conci{
    color: white;
    background-color: rgb(37, 136, 250) !important;
}
.input-flow{
    color: white;
    background-color: rgb(73, 73, 255) !important;
}

.button{
    display: inline-block;
    padding: 0.25rem 1rem;
    height: 35px;
    font-size: 0.9rem;
    font-weight: 500;
    border-top:  1px solid #cfcfcf;
    border-left:  1px solid #cfcfcf;
    border-right:  1px solid #cfcfcf;
    color: white;
}

.button-inactive{
    background: transparent;
}

.button-active{
    background-color: rgba(#cfcfcf, 0.5) !important;
    color: black;
}
.button-primary{
    background-color: #0663ce;
    color: #fff;
}
.button-secundary{
    background-color: #f8fbff;
    color: rgb(7, 73, 255);
}
.button-link{
    color: #0663ce;
    background-color: transparent;
    border-color: transparent;
}
.button:disabled {
    background-color: #fff;
    border: 1px solid #a4a4a4;
    color: #5a5960;
    font-weight: 500;
}
.bg-total{
    border-bottom: 1px solid white;
    border-top: 1px solid white;
    background: rgba(white, 0.5);
}
.bg-title{
    border-bottom: 1px solid white;
    border-top: 1px solid white;
    background: rgb(0, 0, 143);
    color: white;
    font-weight: 400;
}
.bg-title-instructions{
    background: rgb(14,57,100);
    background: linear-gradient(90deg, rgba(14,57,100,1) 0%, rgba(32,143,163,1) 100%);
    color: white;
    font-weight: 400;
}
.bg-title-3{
    border-bottom: 1px solid white;
    border-top: 1px solid white;
    background: rgb(0, 0, 143);
    text-align: center;
    color: white;
    font-weight: bold;
}
.bg-title-2{
    border-bottom: 1px solid white;
    border-top: 1px solid white;
    background: rgb(73, 73, 255);
    color: white;
    font-weight: 600;
}
.bg-section{
    border-bottom: 1px solid white;
    border-top: 1px solid white;
    background: rgb(37, 136, 250);
    text-align: center;
    color: white;
    font-weight: 300;
}
.bg-section-2{
    border-bottom: 1px solid white;
    border-top: 1px solid white;
    background: rgb(37, 136, 250);
    color: white;
    font-weight: 300;
}
.table-registers{
    width: 90%;
    color: rgb(0, 0, 0);
    tr{
        border-radius: 15px !important;
        /* :nth-child(odd) {
            td{
                // background-color: rgba(#cfcfcf, 0.5);
            }
        } */
        td{
            // border-bottom: 1px solid #ccc;
            // background-color: rgba(#cfcfcf, 0.5);
            font-size: 0.9rem;
           /*  &:first-child{
                padding-left: 30px;
            }
            &:last-child{
                border-right: 1px solid #ccc;
            } */
            .icon-search{
                margin-left: -30px;
                width: 30px;
                display: inline-block;
                text-align: center;
            }
            select,
            input{
                display: block;
                width: 100%;
                background-color: transparent;
                /* color: white; */
                /* font-size: 13pt; */
                font-weight: bold;
                text-align: right;
            }
        }
        &.tr-warning{
            td{
                background-color: #fff4d3;
            }
        }
        &.tr-primary{
            position: sticky;
            top: -1px;
            td{
                background-color: #e1f0fb;
                border-top: 2px solid #989898;
            }
        }
    }
}
.box {
    padding: 50px;
    &-center{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &-color{
        border-radius: 15px !important;
        font-size: 20px !important;
        border: 2px solid #fff !important;
        background: rgb(218, 238, 248);
        /*background: linear-gradient(180deg, rgba(62, 165, 220, 1) 0%, rgba(31, 165, 169, 1) 100%) !important; */
    }
}

.title-company{
    color: rgb(0, 0, 0);
    font-weight: bold;
    // background-color: blue !important;
}

.space-td{
    padding-top: 20px;
    padding-bottom: 20px;
}

</style>