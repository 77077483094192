<script>
import questions from '../assets/questions.json'
import logo from '../assets/logo_azul.png';

var pdfMake = require('pdfmake/build/pdfmake.js');
var pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;


import questions_1 from '@/assets/exams/1.json';
import questions_2 from '@/assets/exams/2.json';
import questions_3 from '@/assets/exams/3.json';

export default {
    data() {
        return {
            question_id: '',
            JsonQuestion: questions,
            respons: [],
            responsPractice: null,
            logo: logo,
            logoBase64: ''
        }
    },
    methods: {
        downloadPDF() {
            let ImgBase = this.logoBase64;
            let resp1 = this.primaryP();
            console.log(resp1);
            let content = [{
                image: ImgBase,
                width: 200,
                fit: [100, 100]
            }];
            content = content.concat(resp1);
            let docDefinition = {
                pageMargins: [40, 50, 40, 50],
                content: content,
                styles: {
                    header: {
                        fontSize: 16,
                        bold: true,
                        alignment: 'center',
                        margin: [20, 12]
                    },
                    question: {
                        fontSize: 14,
                        alignment: 'justified',
                        margin: [10, 10]
                    },
                    Rsuccess: {
                        fontSize: 12,
                        alignment: 'justified',
                        margin: [3, 3],
                        color: '#01A3A6',
                    },
                    Rerror: {
                        fontSize: 12,
                        alignment: 'justified',
                        margin: [3, 3],
                        color: '#0F71BC',
                    },
                    HeadT: {
                        fontSize: 12,
                        alignment: 'center',
                        margin: [10, 10],
                        fillColor: '#01A5A6',
                        color: '#ffffff',
                    },
                },
                userPassword: 'bbvafacilitador2023', // Establecer la contraseña del usuario
                ownerPassword: 'bbvafacilitador2023', // Establecer la contraseña del propietario
            };

            // Generar el PDF y descargarlo
            pdfMake.createPdf(docDefinition).download('Respuestas_participante.pdf');

        },
        primaryP() {
            let q1 = [];
            let ind = 0;
            let question = this.JsonQuestion;
            if (this.question_id == 1) {
                this.JsonQuestion = questions_1;
            }
            if (this.question_id == 2) {
                this.JsonQuestion = questions_2;
            }
            if (this.question_id == 3) {
                this.JsonQuestion = questions_3;
            }

            let responses = this.respons;
            question.forEach(function (items, index) {
                let _respon = responses[index];
                // Modulos
                if (items.type == 'group') {
                    if (ind != items.id) {
                        let name = items.text.replace("<br>", "");
                        let obj = { text: name, style: 'header' };
                        q1.push(obj)
                        ind = items.id;
                    }
                }

                if (items.type == 'option' || items.type == 'optionTF') {
                    let name = items.text.replaceAll("<ul>", " ")
                        .replaceAll("</ul>", " ")
                        .replaceAll("<li>", "\n *")
                        .replaceAll("</li>", " ")
                        .replaceAll("<ul style='display: inline-block; text-align: left;'>", " ");
                    let obj = { text: name, style: 'question' };
                    q1.push(obj);
                    let resQ = items.responses[_respon[0]];
                    let objR = { text: resQ.text, style: resQ.correct == true ? 'Rsuccess' : 'Rerror' };
                    q1.push(objR);

                }

                if (items.type == 'complete') {
                    let nameComplete = items.questions.replace("$-0", "[ Respuesta 1 ]")
                        .replace("$-1", "[ Respuesta 2 ]")
                        .replace("$-2", "[ Respuesta 3 ]")
                        .replace("$-3", "[ Respuesta 4 ]")
                        .replace("$-4", "[ Respuesta 5 ]")
                    let objComplete = { text: nameComplete, style: 'question' };
                    q1.push(objComplete);
                    let cont = 1;
                    _respon.forEach(function (itemsR, iR) {
                        let resQ = items.responses[itemsR];
                        let objR = { text: `Respuesa ${cont} : ${resQ.text}`, style: resQ.question == iR ? 'Rsuccess' : 'Rerror' };
                        cont++;
                        q1.push(objR);
                    });
                }

                if (items.type == 'ralation') {
                    let name = items.text;
                    let obj = { text: name, style: 'question' };
                    q1.push(obj);

                    let rT = [[
                        { text: 'Pregunta', style: 'HeadT' },
                        { text: 'Repuestas', style: 'HeadT' }
                    ]];
                    items.questions.forEach(function (itemsR, iR) {
                        let Pr = _respon[iR];
                        let ArR = [itemsR.text, { text: items.responses[iR].name, style: Pr == iR ? 'Rsuccess' : 'Rerror' }];
                        rT.push(ArR);
                    });

                    let objR = {
                        table: {
                            headerRows: 1,
                            widths: ['*', '*'],
                            body: rT
                        }
                    };
                    q1.push(objR);

                }

            });
            q1.push(this.responsPractice);
            return q1;
        },
        getBase64ImageFromURL(url) {
            return new Promise((resolve, reject) => {
                var img = new Image();
                img.setAttribute("crossOrigin", "anonymous");

                img.onload = () => {
                    var canvas = document.createElement("canvas");
                    canvas.width = img.width;
                    canvas.height = img.height;

                    var ctx = canvas.getContext("2d");
                    ctx.drawImage(img, 0, 0);

                    var dataURL = canvas.toDataURL("image/png");

                    resolve(dataURL);
                };

                img.onerror = error => {
                    reject(error);
                };

                img.src = url;
            });
        }
    },
    created() {
        Event.$on("event-load-responses_1", (responses, question_id) => {
            this.question_id = question_id;
            this.respons = responses;
        });
        Event.$on("event-load-responses_2", (responses) => {
            this.responsPractice = responses;
        });
        Event.$on("event-download-pdf", () => {
            this.downloadPDF();
        });
    },
    mounted() {
        let logo = this.logo;
        this.getBase64ImageFromURL(logo).then((value) => {
            this.logoBase64 = value;
        });

    }
}
</script>
<template>
    <div>
    </div>
</template>
