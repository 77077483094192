export default {
    resultStatus: {
        2021: {
            national_net_sales: 9435,
            export_net_sales: 0,
            sum_net_sales: 0,
            sales_cost: 6443,
            gross_profit: 0,
            amortization: 0,
            depreciation: 78,
            admnistration_expenses: 2217,
            gross_profit_before: 0,
            ebitda: 0,
            loss_utility_change: 0,
            loss_utility_posmon: 0,
            financial_product: 0,
            financial_expense: 257,
            cost_financing: 0,
            other_expense: 0,
            utility_before_isr_ptu: 0,
            utility_before_taxes: 0,
            taxes_ptu_deferred: 0,
            impac_causado: 0,
            isr_causado: 182,
            utility_before_partida: 0,
            loss_sub_afils: 0,
            utility_sub_afils: 0,
            utility_loss_net: 0,
        },
        2022: {
            national_net_sales: 12283,
            export_net_sales: 0,
            sum_net_sales: 0,
            sales_cost: 8779,
            gross_profit: 0,
            amortization: 0,
            depreciation: 88,
            admnistration_expenses: 2734,
            gross_profit_before: 0,
            ebitda: 0,
            loss_utility_change: 0,
            loss_utility_posmon: 0,
            financial_product: 0,
            financial_expense: 267,
            cost_financing: 0,
            other_expense: 0,
            utility_before_isr_ptu: 0,
            utility_before_taxes: 0,
            taxes_ptu_deferred: 0,
            impac_causado: 0,
            isr_causado: 169,
            utility_before_partida: 0,
            loss_sub_afils: 0,
            utility_sub_afils: 0,
            utility_loss_net: 0,
        }
    },
    step1: {
        2021: {
            activos_circulantes: {
                caja_banco: 73,
                inversiones_temporales: 0,
                clientes_docs_cobrar: 2514,
                inventarios: 769,
                cuentas_cobrar_filiales: 0,
                pagos_anticipados: 159,
                otros_act_circ_operativos: 0,
                deudores_diversos: 0,
                otros_act_circ_no_operativos: 0,
                suma_activo_circulante: 0,
            },
            activo_fijo: {
                prop_planta_equipo_net: 1000,
                inversion_subsidiarias: 0,
                cuentas_cobrar_filiales_fijo: 0,
                otros_activos_fijos: 466,
                suma_activo_fijo: 0,
            },
            otros_activos: {
                activos_impuestos_diferidos: 0,
                patentes_licencias_marcas: 0,
                credito_mercantil: 0,
                cargos_diferidos: 0,
                suma_otros_activos: 0,
            },
            total_activos: 0,
            pasivo_corto_plazo:{
                bacomer_corto_plazo: 0,
                otros_bancos_corto_plazo: 1800,
                emesion_deuda_corto_plazo: 0,
                porc_circ_largo_plazo_bancomer: 0,
                porc_circ_largo_plazo_otros_banc: 78,
                proveedores: 241,
                impuestos_pagar: 112,
                cuentas_pagar_filiales: 0,
                gastos_acumulados: 53,
                otros_pasivos_circ_operativos: 0,
                otros_pasivos_circ_no_operativos: 0,
                suma_pasivo_corto_plazo: 0,
            },
            pasivo_largo_plazo: {
                bancomer_largo_plazo: 0,
                otros_bancos_largo_plazo: 0,
                emisiones_deuda_largo_plazo: 0,
                cuentas__pagar_filiales_lp: 0,
                otros_pasivos_largo_plazo: 0,
                primas_antiguedad: 0,
                pasivos_diferidos: 0,
                pasivos_impuesto_diferidos: 0,
                suma_pasivo_largo_plazo: 0,
            },
            total_pasivos: 0,
            capital: {
                capital_social_act: 250,
                aportaciones_pendientes_cap: 0,
                prima_emisiones_acciones: 0,
                interes_minoritario: 0,
                exc_actualizacion_capital: 0,
                impacto_isr_diferido: 0,
                reservas: 0,
                utilizades_retenidas: 2189,
                utilidad_ejercicio: 258,
                suma_cap_contable: 0,
            },
            suma_pasivo_capital: 0,
            diferencia: 0,
        },
        2022: {
            activos_circulantes: {
                caja_banco: 52,
                inversiones_temporales: 0,
                clientes_docs_cobrar: 3229,
                inventarios: 1440,
                cuentas_cobrar_filiales: 0,
                pagos_anticipados: 219,
                otros_act_circ_operativos: 100,
                deudores_diversos: 0,
                otros_act_circ_no_operativos: 0,
                suma_activo_circulante: 0
            },
            activo_fijo: {
                prop_planta_equipo_net: 1069,
                inversion_subsidiarias: 0,
                cuentas_cobrar_filiales_fijo: 0,
                otros_activos_fijos: 158,
                suma_activo_fijo: 0,
            },
            otros_activos: {
                activos_impuestos_diferidos: 0,
                patentes_licencias_marcas: 0,
                credito_mercantil: 0,
                cargos_diferidos: 0,
                suma_otros_activos: 0,
            },
            total_activos: 0,
            pasivo_corto_plazo:{
                bacomer_corto_plazo: 0,
                otros_bancos_corto_plazo: 2153,
                emesion_deuda_corto_plazo: 0,
                porc_circ_largo_plazo_bancomer: 0,
                porc_circ_largo_plazo_otros_banc: 129,
                proveedores: 394,
                impuestos_pagar: 125,
                cuentas_pagar_filiales: 0,
                gastos_acumulados: 129,
                otros_pasivos_circ_operativos: 0,
                otros_pasivos_circ_no_operativos: 0,
                suma_pasivo_corto_plazo: 0,
            },
            pasivo_largo_plazo: {
                bancomer_largo_plazo: 394,
                otros_bancos_largo_plazo: 0,
                emisiones_deuda_largo_plazo: 0,
                cuentas__pagar_filiales_lp: 0,
                otros_pasivos_largo_plazo: 0,
                primas_antiguedad: 0,
                pasivos_diferidos: 0,
                pasivos_impuesto_diferidos: 0,
                suma_pasivo_largo_plazo: 0,
            },
            total_pasivos: 0,
            capital: {
                capital_social_act: 250,
                aportaciones_pendientes_cap: 0,
                prima_emisiones_acciones: 0,
                interes_minoritario: 0,
                exc_actualizacion_capital: 0,
                impacto_isr_diferido: 0,
                reservas: 0,
                utilizades_retenidas: 2447,
                utilidad_ejercicio: 246,
                suma_cap_contable: 0,
            },
            suma_pasivo_capital: 0,
            diferencia: 0,
        },
        diff: {
            activos_circulantes: {
                caja_banco: 0,
                inversiones_temporales: 0,
                clientes_docs_cobrar: 0,
                inventarios: 0,
                cuentas_cobrar_filiales: 0,
                pagos_anticipados: 0,
                otros_act_circ_operativos: 0,
                deudores_diversos: 0,
                otros_act_circ_no_operativos: 0,
                suma_activo_circulante: 0
            },
            activo_fijo: {
                prop_planta_equipo_net: 0,
                inversion_subsidiarias: 0,
                cuentas_cobrar_filiales_fijo: 0,
                otros_activos_fijos: 0,
                suma_activo_fijo: 0,
            },
            otros_activos: {
                activos_impuestos_diferidos: 0,
                patentes_licencias_marcas: 0,
                credito_mercantil: 0,
                cargos_diferidos: 0,
                suma_otros_activos: 0,
            },
            total_activos: 0,
            pasivo_corto_plazo:{
                bacomer_corto_plazo: 0,
                otros_bancos_corto_plazo: 0,
                emesion_deuda_corto_plazo: 0,
                porc_circ_largo_plazo_bancomer: 0,
                porc_circ_largo_plazo_otros_banc: 0,
                proveedores: 0,
                impuestos_pagar: 0,
                cuentas_pagar_filiales: 0,
                gastos_acumulados: 0,
                otros_pasivos_circ_operativos: 0,
                otros_pasivos_circ_no_operativos: 0,
                suma_pasivo_corto_plazo: 0,
            },
            pasivo_largo_plazo: {
                bancomer_largo_plazo: 0,
                otros_bancos_largo_plazo: 0,
                emisiones_deuda_largo_plazo: 0,
                cuentas__pagar_filiales_lp: 0,
                otros_pasivos_largo_plazo: 0,
                primas_antiguedad: 0,
                pasivos_diferidos: 0,
                pasivos_impuesto_diferidos: 0,
                suma_pasivo_largo_plazo: 0,
            },
            total_pasivos: 0,
            capital: {
                capital_social_act: 0,
                aportaciones_pendientes_cap: 0,
                prima_emisiones_acciones: 0,
                interes_minoritario: 0,
                exc_actualizacion_capital: 0,
                impacto_isr_diferido: 0,
                reservas: 0,
                utilizades_retenidas: 0,
                utilidad_ejercicio: 0,
                suma_cap_contable: 0,
            },
            suma_pasivo_capital: 0,
            diferencia: 0,
        },
        entrance_exit: {
            activos_circulantes: {
                caja_banco: 0,
                inversiones_temporales: 0,
                clientes_docs_cobrar: 0,
                inventarios: 0,
                cuentas_cobrar_filiales: 0,
                pagos_anticipados: 0,
                otros_act_circ_operativos: 0,
                deudores_diversos: 0,
                otros_act_circ_no_operativos: 0,
                suma_activo_circulante: 0
            },
            activo_fijo: {
                prop_planta_equipo_net: 0,
                inversion_subsidiarias: 0,
                cuentas_cobrar_filiales_fijo: 0,
                otros_activos_fijos: 0,
                suma_activo_fijo: 0,
            },
            otros_activos: {
                activos_impuestos_diferidos: 0,
                patentes_licencias_marcas: 0,
                credito_mercantil: 0,
                cargos_diferidos: 0,
                suma_otros_activos: 0,
            },
            total_activos: 0,
            pasivo_corto_plazo:{
                bacomer_corto_plazo: 0,
                otros_bancos_corto_plazo: 0,
                emesion_deuda_corto_plazo: 0,
                porc_circ_largo_plazo_bancomer: 0,
                porc_circ_largo_plazo_otros_banc: 0,
                proveedores: 0,
                impuestos_pagar: 0,
                cuentas_pagar_filiales: 0,
                gastos_acumulados: 0,
                otros_pasivos_circ_operativos: 0,
                otros_pasivos_circ_no_operativos: 0,
                suma_pasivo_corto_plazo: 0,
            },
            pasivo_largo_plazo: {
                bancomer_largo_plazo: 0,
                otros_bancos_largo_plazo: 0,
                emisiones_deuda_largo_plazo: 0,
                cuentas__pagar_filiales_lp: 0,
                otros_pasivos_largo_plazo: 0,
                primas_antiguedad: 0,
                pasivos_diferidos: 0,
                pasivos_impuesto_diferidos: 0,
                suma_pasivo_largo_plazo: 0,
            },
            total_pasivos: 0,
            capital: {
                capital_social_act: 0,
                aportaciones_pendientes_cap: 0,
                prima_emisiones_acciones: 0,
                interes_minoritario: 0,
                exc_actualizacion_capital: 0,
                impacto_isr_diferido: 0,
                reservas: 0,
                utilizades_retenidas: 0,
                utilidad_ejercicio: 0,
                suma_cap_contable: 0,
            },
            suma_pasivo_capital: 0,
            diferencia: 0,
        }
    },
    conciliation: {
        only: {
            inv_inventarios: {
                inventario_final: 1440,
                inventario_inicial: 769,
                actualizacion_ejercicio: 671,
                inversion_neta_inventario: 0
            },
            revaluacion_subsidarias: {
                inversion_final_subsidiarias: 0,
                inversion_inicial_subsidiarias: 0,
                inversion_venta_efectivo: 0,
                dividendos_efectivo: 0,
                utilidad_perdida_part: 0,
                revaluacion_ejercicio: 0,
            },
            inversion_propiedad: {
                prop_planta_eq_final: 1069,
                prop_planta_eq_incial: 1000,
                revaluacion_ejercicio_prop: 157,
                depreciacion_ejercicio: 88,
                inversion_neta_prop: 0,
            },
            actualizacion_capital: {
                capital_contable_final: 2943,
                capital_contable_inicial: 2697,
                aportacion_accionista_efectivo: 0,
                dividendos_pagados_efectivo: 0,
                utilidad_ejercicio: 246,
                actualizacion_ejercicio_ac: 0
            },
            actualizacion_resultados: {
                actualizacion_capital_d: 0,
                uti_perdida_posicion: 0,
                actualizacion_inventarios: 671,
                revaluacion_inversion_sub: 0,
                revaluacion_activo_fijo: 157,
                sum_actualizacion_resultados: -828,
            },
            cargos_no_monetarios: {
                isr_ptu_activo_diferido: 0,
                patentes_licencias: 0,
                credito_mercantil: 0,
                cargos_diferidos: 0,
                primas_antiguedad: 0,
                pasivos_diferidos: 0,
                isr_ptu_pasivos_diferidos: 0,
                actualizacion_resultados_ca: -828,
                amortization_ca: 0,
                cargos_no_monetarios_ca: -828,
            },
            isr_ptu: {
                isr_ptu_to: 169,
                isr_ptu_diferido_ejercicio: 0,
                financiamiento_fiscal: 0,
                isr_ptu_diferido_pasivo: 0,
                isr_ptu_diferido_activo: 0,
                isr_ptu_impac_pagados: 169,
            },
            perdida_utilidad_cambiaria: {
                perdida_utilidad_cam_ejer: 0,
                incluida_deuda_cor_pla: 0,
                incluida_deuda_lar_pla: 0,
                incluida_clientes: 0,
                incluida_inventarios: 0,
                incluida_proveedores: 0,
                perdida_utilidad_cam_pag: 0,

            }
        }
    },
    cash_flow: {
        2022: {
            uafir: 682,
            depreciacion: 88,
            amortizaciones: 0,
            result_extra_no_mone: 0,
            otros_gastos_igre_no_mone: 0,
            cargos_no_monetarios: -828,
            flujo_bruto: -58,
            variacion_client_doc_cobrar: 0,
            variacion_inventario: 0,
            varia_ctas_cobrar_afi: 0,
            variacion_pagos_anticipados: 0,
            variacion_otros_activos: 0,
            variacion_proveedores: 0,
            varia_ctas_pagar_afi: 0,
            varia_gatos_acumulados: 0,
            variacion_otros_pasivos: 0,
            capital_trabajo_neto: 0,
            flujo_operacion: -58,
            impuestos_pagados: 169,
            otros_gastos_ingresos: 0,
            result_extra_efectivo: 169,
            flujo_neto: -227,
            gastos_financieros_pagados: -267,
            perdida_utilidad_cambiaria_pag: 0,
            productos_financieros: 0,
            compromisos_financieros: -267,
            flujo_antes_inversion: -494,
            variacion_neta_prop_pta_equi: 0,
            variacion_sub_afi: 0,
            dividendos_recibidos_efectivo: 0,
            variacion_deudores_diversos: 0,
            variacion_otros_activos_no_ope: 0,
            variacion_patentes_licencias: 0,
            variacion_credito_mercantil: 0,
            variacion_cargos_diferidos: 0,
            variacion_pasivos_diferidos: 0,
            variacion_otros_pasivos_no_ope: 0,
            dividendos_pagados_efectivo: 0,
            pago_prima_antiguedad: 0,
            total_inversiones: 0,
            flujo_necesario_excedentes: -494,
            porcion_circ_deuda_largo_pla: 0,
            variacion_deuda_cor_plazo: 0,
            variacion_deuda_lar_plazo: 0,
            variacion_emision_deuda_cor_plazo: 0,
            variacion_emision_deuda_lar_plazo: 0,
            variacion_ctas_cobrar_lar_plazo: 0,
            variacion_ctas_pagar_lar_plazo: 0,
            aportacion_accionista_efectivo: 0,
            total_financiamiento: 0,
            aumento_disminucion_caja: -494,
            variacion_caja_flujo: 494,
        }
    },
    qualitative: {
        basic: '',
    },
    // respuiestas correctas a evaluar
    answer_correct: {
        diff_caja: -21,
        diff_evaluar: {
            page: 'step1',
            section: 'activos_circulantes',
            key: 'caja_banco',
            period: 'diff'
        },
        variacion_caja_flujo: 0,
        variacion_evaluar: {
            page: 'cash_flow',
            key: 'variacion_caja_flujo',
            period: 2022
        },
        phrases: ['UAFIRDA', 'FLUJO DE OPERACION', 'FLUJO NETO', 'FLUJO NECESARIO O EXCELENTE', 'VARIACION DE CAJA ES 0'],
    }
    
    /* ,
    step2: {
        diff: {
            activos_circulantes: {
                caja_banco: 0,
                inversiones_temporales: 0,
                clientes_docs_cobrar: 0,
                inventarios: 0,
                cuentas_cobrar_filiales: 0,
                pagos_anticipados: 0,
                otros_act_circ_operativos: 0,
                deudores_diversos: 0,
                otros_act_circ_no_operativos: 0,
            },
            activo_fijo: {
                prop_planta_equipo_net: 0,
                inversion_subsidiarias: 0,
                cuentas_cobrar_filiales_fijo: 0,
                otros_activos_fijos: 0,
                suma_activo_fijo: 0,
            },
            otros_activos: {
                activos_impuestos_diferidos: 0,
                patentes_licencias_marcas: 0,
                credito_mercantil: 0,
                cargos_diferidos: 0,
                suma_otros_activos: 0,
            },
            total_activos: 0,
            pasivo_corto_plazo:{
                bacomer_corto_plazo: 0,
                otros_bancos_corto_plazo: 0,
                emesion_deuda_corto_plazo: 0,
                porc_circ_largo_plazo_bancomer: 0,
                porc_circ_largo_plazo_otros_banc: 0,
                proveedores: 0,
                impuestos_pagar: 0,
                cuentas_pagar_filiales: 0,
                gastos_acumulados: 0,
                otros_pasivos_circ_operativos: 0,
                otros_pasivos_circ_no_operativos: 0,
                suma_pasivo_corto_plazo: 0,
            },
            pasivo_largo_plazo: {
                bancomer_largo_plazo: 0,
                otros_bancos_largo_plazo: 0,
                emisiones_deuda_largo_plazo: 0,
                cuentas__pagar_filiales_lp: 0,
                otros_pasivos_largo_plazo: 0,
                primas_antiguedad: 0,
                pasivos_diferidos: 0,
                pasivos_impuesto_diferidos: 0,
                suma_pasivo_largo_plazo: 0,
            },
            total_pasivos: 0,
            capital: {
                capital_social_act: 0,
                aportaciones_pendientes_cap: 0,
                prima_emisiones_acciones: 0,
                interes_minoritario: 0,
                exc_actualizacion_capital: 0,
                impacto_isr_diferido: 0,
                reservas: 0,
                utilizades_retenidas: 0,
                utilidad_ejercicio: 0,
                suma_cap_contable: 0,
            },
            suma_pasivo_capital: 0,
            diferencia: 0,
        },
        entrance_exit: {
            activos_circulantes: {
                caja_banco: 0,
                inversiones_temporales: 0,
                clientes_docs_cobrar: 0,
                inventarios: 0,
                cuentas_cobrar_filiales: 0,
                pagos_anticipados: 0,
                otros_act_circ_operativos: 0,
                deudores_diversos: 0,
                otros_act_circ_no_operativos: 0,
            },
            activo_fijo: {
                prop_planta_equipo_net: 0,
                inversion_subsidiarias: 0,
                cuentas_cobrar_filiales_fijo: 0,
                otros_activos_fijos: 0,
                suma_activo_fijo: 0,
            },
            otros_activos: {
                activos_impuestos_diferidos: 0,
                patentes_licencias_marcas: 0,
                credito_mercantil: 0,
                cargos_diferidos: 0,
                suma_otros_activos: 0,
            },
            total_activos: 0,
            pasivo_corto_plazo:{
                bacomer_corto_plazo: 0,
                otros_bancos_corto_plazo: 0,
                emesion_deuda_corto_plazo: 0,
                porc_circ_largo_plazo_bancomer: 0,
                porc_circ_largo_plazo_otros_banc: 0,
                proveedores: 0,
                impuestos_pagar: 0,
                cuentas_pagar_filiales: 0,
                gastos_acumulados: 0,
                otros_pasivos_circ_operativos: 0,
                otros_pasivos_circ_no_operativos: 0,
                suma_pasivo_corto_plazo: 0,
            },
            pasivo_largo_plazo: {
                bancomer_largo_plazo: 0,
                otros_bancos_largo_plazo: 0,
                emisiones_deuda_largo_plazo: 0,
                cuentas__pagar_filiales_lp: 0,
                otros_pasivos_largo_plazo: 0,
                primas_antiguedad: 0,
                pasivos_diferidos: 0,
                pasivos_impuesto_diferidos: 0,
                suma_pasivo_largo_plazo: 0,
            },
            total_pasivos: 0,
            capital: {
                capital_social_act: 0,
                aportaciones_pendientes_cap: 0,
                prima_emisiones_acciones: 0,
                interes_minoritario: 0,
                exc_actualizacion_capital: 0,
                impacto_isr_diferido: 0,
                reservas: 0,
                utilizades_retenidas: 0,
                utilidad_ejercicio: 0,
                suma_cap_contable: 0,
            },
            suma_pasivo_capital: 0,
            diferencia: 0,
        }
    } */
}